import { useState, useEffect } from 'react';
import moment from 'moment';
import { LiaReplySolid } from "react-icons/lia";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoSend, IoChevronBack } from "react-icons/io5";
import { FaRegTimesCircle } from "react-icons/fa";

const OpenMessageMobile = ({ data, emit }) => {
  const [showReplyPanel, setShowReplyPanel] = useState(false);
  const [showMessage, setShowMessage] = useState(null)
  const [replyInput, setReplyInput] = useState("");

  const handleDelete = () => {
    setReplyInput("");
  }

  const handleReply = async () => {

  }

  const sendDataToParent = () => {
    emit({
      type: "setShowMessage",
      data: showMessage,
    });
  }

  const formatDateTime = (timestamp) => {
    const response = `${moment(timestamp).format('hh:mm A')} (${moment(timestamp).fromNow()})`;
    return response;
  }

  const handleCloseReplyPanel = () => {
    setShowReplyPanel(false);
  }

  // FIX RESPONSE FOR TEMPORARY
  const messages = [
    { id: 1, type: 'received', content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam consequat massa a ipsum suscipit, quis vestibulum ante semper. Suspendisse nec purus lorem. Maecenas dignissim, purus vel finibus ullamcorper, massa nisi venenatis diam, sed condimentum orci velit et dui. ", timestamp: new Date() },
    { id: 2, type: 'reply', content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam consequat massa a ipsum suscipit, quis vestibulum ante semper. Suspendisse nec purus lorem. Maecenas dignissim, purus vel finibus ullamcorper, massa nisi venenatis diam, sed condimentum orci velit et dui. ", timestamp: new Date() }
  ];

  return (
    <div className="flex flex-col self-stretch px-8 max-md:px-3 max-md:mt-8 max-md:max-w-full">
      <div className="flex flex-wrap gap-2.5 content-center mt-6 text-2xl font-semibold tracking-tight leading-7 text-blue-900">
      <div className="flex cursor-pointer gap-1 items-center justify-start"
          onClick={() => {
              setShowMessage(false);
              sendDataToParent();
          }}
      >
          <IoChevronBack size={20} className="self-right"/>
          <div className="flex max-md:max-w-full">
            {data.subject}
          </div>
        </div>
      </div>
      <div className="flex gap-2.5 justify-between mt-2 text-xs font-light tracking-tight leading-4 text-neutral-600 max-md:flex-wrap">
        <div className="flex gap-1 my-auto max-md:max-w-full">
          from: <span className='text-[#0082ba] font-semibold'>
            {data.sender.fullname}
          </span>
        </div>
        <div className="flex gap-2 max-md:flex-wrap">
          <div className="flex my-auto text-right">
            {formatDateTime(data.created_at)}
          </div>
          <div className="flex gap-2 px-px whitespace-nowrap">
            <div
              className="flex gap-2 cursor-pointer text-[#0082ba]"
              onClick={() => setShowReplyPanel(true)}
            >
              <LiaReplySolid size={15} className='cursor-pointer' />
              <div className="my-auto">Reply</div>
            </div>
            <FaRegTrashCan onClick={() => handleDelete()} size={15} className="cursor-pointer text-gray-600 hover:text-red-500" />
          </div>
        </div>
      </div>

      {/* <div className="justify-center p-8 mt-2 text-sm font-light tracking-tight leading-4 rounded-2xl border border-solid border-zinc-200 text-neutral-600 max-md:px-5 max-md:max-w-full">
        {data.message_content}
      </div> */}

      {/* Message Section */}
      <div className='flex flex-col items-center h-auto overflow-auto mt-2'>
        {messages.map((msg) => (
          <div
            key={msg.id}
            className={`justify-center p-6 mt-4 rounded-lg text-sm font-light max-w-full tracking-tight leading-4 border border-solid border-gray-300 text-neutral-600 max-md:px-5 max-md:max-w-full ${msg.type === 'received' ? 'self-start rounded-bl-none' : 'self-end bg-[#dcf9ff] rounded-br-none'}`}
          >
            <div>{msg.content}</div>
            <div className={`italic text-xs text-gray-500 mt-2 ${msg.type === 'received' ? 'text-start' : 'text-end'}`}>{formatDateTime(msg.timestamp)}</div>
          </div>
        ))}
      </div>

      {showReplyPanel && (
        <div className="flex gap-4 mt-8 max-md:flex-wrap">
          <img
            alt="profile"
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d1f6218a449597f3a8bc713b85905e3bdb9e14b0e6e958134a97eb98c7ddb99b"
            className="shrink-0 self-start w-10 rounded-full aspect-square border border-devRegGreen"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full border border-gray-300 rounded-lg">
            <div className="flex justify-between items-center px-4 py-4 rounded-t-lg">
              <div className="flex items-center gap-2.5 text-sm">
                <LiaReplySolid size={15} />
                <span>{data.sender.email}</span>
              </div>
              <FaRegTimesCircle
                onClick={handleCloseReplyPanel}
                size={20}
                className="cursor-pointer text-gray-600 hover:text-red-500"
              />
            </div>

            <textarea
              onChange={(replyInput) => setReplyInput(replyInput.target.value)}
              value={replyInput}
              className="py-2 px-4 text-sm font-light w-full min-h-[100px] border border-transparent focus:outline-none outline-none"
              placeholder="Type your reply here..."
            />

            <div className="flex justify-between items-center px-4 py-2 rounded-b-lg border-t border-gray-300">
              <FaRegTrashCan
                size={20}
                className="cursor-pointer text-gray-600 hover:text-red-500"
              />
              <button
                onClick={() => handleReply()}
                className="flex items-center gap-2 px-6 py-2 text-white bg-blue-900 rounded-full hover:bg-blue-700 focus:outline-none"
              >
                Send <IoSend size={20} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default OpenMessageMobile
