import React from "react";

function MissionVision() {
  return (
    <div className="max-w-main px-10 m-auto bg-[#FAFAFA] rounded-2xl mt-20 pb-20 max-md:px-3 max-md:mt-10 max-md:pb-14">
      <div className="font-bold text-2xl sm:text-3xl lg:text-[30px] text-devRegDarkBlue mb-8 max-md:px-3">
          Mission and Vision
      </div>
      <div className="flex justify-between gap-12 max-md:px-3 items-start max-[900px]:flex-col max-md:w-full max-[900px]:items-center">
        <div className="flex w-1/2 flex-col gap-5 bg-white rounded-lg p-6 border border-[#E5E7EB] max-[900px]:w-full h-[308px] max-[425px]:h-[410px]">
          <img
            src="/AboutUsLogo-1.svg"
            alt="AboutUsLogo"
            className="w-[90px] h-[90px]"
          />
          <div>
            <div className="font-bold text-[24px] text-devRegDarkBlue">The Mission</div>
            <p className="text-sm16 mt-4 max-w-full text-devRegDarkBlue">
              Committed to endorsing software developers, placing honesty and
              integrity at the forefront of our values.
            </p>
          </div>
        </div>
        <div className="flex w-1/2 flex-col gap-5 bg-white rounded-lg p-6 border border-[#E5E7EB] max-[900px]:w-full">
          <img
            src="/AboutUsLogo-2.svg"
            alt="AboutUsLogo"
            className="w-[90px] h-[90px]"
          />
          <div>
            <div className="font-bold text-[24px] text-devRegDarkBlue">The Vision</div>
            <p className="text-sm16 mt-4 max-w-full text-devRegDarkBlue">
              To offer software developers worldwide the opportunity to prove to
              clients that{" "}
              <span className="font-bold text-[16px]  text-devRegDarkBlue">
                “They Are Who They Say They Are”{" "}
              </span>{" "}
              - not scammers hiding behind a computer screen but competent and
              trustworthy professionals with a strong sense of integrity and
              accountability.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MissionVision;