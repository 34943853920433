import React, { useState, useEffect } from "react";
import Loading from "../../../../component/Loading.js";
import {
  getWorkHistory,
  addWorkHistory,
  editWorkHistory,
  deleteWorkHistory,
  addWorkHistoryProjects,
} from "../../../../api/apiCall.js";
import moment from "moment";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

function WorkHistory() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const [actionResponse, setActionResponse] = useState({ status: null, message: null });
  const [isDeleting, setIsDeleting] = useState({ id: null, deleted: null });
  const [isLoading, setIsLoading] = useState({ adding: false, fetching: false });
  const [updatingId, setUpdatingId] = useState(null);

  const [workHistory, setWorkHistory] = useState([]);
  const [action, setAction] = useState('Add');
  const [companyLogoPreview, setCompanyLogoPreview] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [position, setPosition] = useState(null);
  const [dateStarted, setDateStarted] = useState(null);
  const [dateEnded, setDateEnded] = useState(null);
  const [workArrangement, setWorkArrangement] = useState(null);
  const [jobDescription, setJobDescription] = useState(null);
  const [addProjects, setAddProjects] = useState([
    { title: "", category: "", description: "Testing Test", thumbnail: null, thumbnail_path: null, is_featured: 0 },
  ]);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (actionResponse) {
      const timer = setTimeout(() => {
        setVisible(false);
        setActionResponse(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [actionResponse]);

  const handleClose = () => {
    setVisible(false);
    setActionResponse(false);
  };

  useEffect(() => {
    const iniialFetch = async () => {
      setIsLoading((prev) => ({ ...prev, fetching: true }));
      await handleGetWorkHistory();
      setIsLoading((prev) => ({ ...prev, fetching: false }));
    }

    iniialFetch();
  }, [])

  const handleGetWorkHistory = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
      }

      const response = await getWorkHistory(data);
      if (response.status === 200) {
        setWorkHistory(response.data)
        setIsDeleting({ id: null, deleted: null });
      }
    }
    catch (error) {
      console.log("Error getting work history: ", error);
    }
  }

  const handleAddWorkHistory = async () => {
    setIsLoading((prev) => ({ ...prev, adding: true }));
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          thumbnail: companyLogo,
          company_name: companyName,
          position: position,
          date_started: dateStarted,
          date_ended: dateEnded,
          // is_present: dateEnded ? true : '',
          work_arrangement: workArrangement,
          // work_arrangement_other: ,
          job_description: jobDescription,
        }
      }

      const response = await addWorkHistory(data);
      if (response.status === 201) {
        await handleAddWorkHistoryProjects(response.data.data.id)
        setCompanyLogoPreview('');
        setCompanyName('');
        setPosition('');
        setDateStarted('');
        setDateEnded('');
        setWorkArrangement('');
        setJobDescription('');
      }
    }
    catch (error) {
      console.log("Error adding work experience: ", error);
    }
    finally {
      setIsLoading((prev) => ({ ...prev, adding: false }));
    }
  }

  const handleDeleteWorkHistory = async (workHistoryId) => {
    setIsDeleting({ id: workHistoryId, deleted: null })
    try {
      let data = {
        id: user.id,
        token: token,
        workHistoryId: workHistoryId
      }

      const response = await deleteWorkHistory(data);
      if (response.status === 200) {
        setActionResponse({ status: null, message: null });
        setIsDeleting({ id: workHistoryId, deleted: true });
        await handleGetWorkHistory();
      }
    }
    catch (error) {
      console.log("Error deleting work experience: ", error);
      // setIsDeleting({id: workHistoryId, deleted: false});
      // handleGetWorkHistory();
    }
    finally {
      setIsDeleting({ id: null, deleted: null })
    }
  }

  const handleAddWorkHistoryProjects = async (workHistoryId) => {
    try {
      addProjects.forEach(async (item) => {
        let data = {
          id: user.id,
          token: token,
          workHistoryId: workHistoryId,
          payloads: {
            title: item.title,
            category: item.category,
            description: item.description,
            thumbnail: item.thumbnail,
            is_featured: item.is_featured,
          }
        }

        const response = await addWorkHistoryProjects(data);
        if (response.status === 201) {

        }
      })

      setCompanyLogoPreview('');
      setCompanyLogo('');
      setCompanyName('');
      setPosition('');
      setDateStarted('');
      setDateEnded('');
      setWorkArrangement('');
      setJobDescription('');
      setActionResponse({ status: true, message: 'Work Experience Added' });
      setAddProjects([
        { title: "", category: "", description: "Testing Test", thumbnail: null, thumbnail_path: null, is_featured: 0 }
      ])
      handleGetWorkHistory();
    }
    catch (error) {
      console.log("Error adding work projects: ", error);
    }
    finally {

    }
  }

  const handleCompanyLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCompanyLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setCompanyLogo(file);
    }
  };

  const addProjectContainer = () => {
    setAddProjects([...addProjects, { title: "", category: "", description: "Testing Test", thumbnail: null, thumbnail_path: null, is_featured: 0 }]);
  };

  const handleInputChangeProjectContainer = (index, field, value) => {
    setAddProjects((prevAddProjects) => {
      return prevAddProjects.map((project, i) => {
        if (i === index) {
          return {
            ...project,
            [field]: value,
          };
        }
        return project;
      });
    });
  };

  const handleFileChangeProjectContainer = (index, file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setAddProjects((prevAddProjects) => {
        return prevAddProjects.map((project, i) => {
          if (i === index) {
            return {
              ...project,
              thumbnail: file,
              thumbnail_path: reader.result,
            };
          }
          return project;
        });
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  function formatDate(date) {
    return moment(date).format('MMM YYYY');
  }

  const handleUpdateWorkHistory = (data) => {
    window.scrollTo(0, 0)
    setActionResponse({ status: null, message: null });
    setAction('Update');
    setUpdatingId(data.id);
    setCompanyLogoPreview(data.company_logo);
    setCompanyName(data.company_name);
    setPosition(data.position);
    setDateStarted(data.date_started);
    setDateEnded(data.date_ended);
    setWorkArrangement(data.work_arrangement);
    setJobDescription(data.job_description);
    setAddProjects(data.projects);
  }

  const handleCancelUpdateWorkHistory = () => {
    setAction('Add');
    setUpdatingId('');
    setCompanyLogoPreview('');
    setCompanyName('');
    setPosition('');
    setDateStarted('');
    setDateEnded('');
    setWorkArrangement('');
    setJobDescription('');
    setAddProjects([
      { title: "", category: "", description: "Testing Test", thumbnail: null, thumbnail_path: null, is_featured: 0 },
    ]);
  }

  const handleSaveUpdateWorkHistory = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
        workHistoryId: updatingId,
        payloads: {
          thumbnail: companyLogo,
          company_name: companyName,
          position: position,
          date_started: dateStarted,
          date_ended: dateEnded,
          // is_present: !dateEnded ? true : false,
          work_arrangement: workArrangement,
          // work_arrangement_other: ,
          job_description: jobDescription,
        }
      }

      const response = await editWorkHistory(data);
      if (response.status === 200) {
        await handleGetWorkHistory();
        setAction('Add');
        setUpdatingId('');
        setCompanyLogoPreview('');
        setCompanyName('');
        setPosition('');
        setDateStarted('');
        setDateEnded('');
        setWorkArrangement('');
        setJobDescription('');
        // handleAddWorkHistoryProjects(response.data.data.id)
        setActionResponse({ status: true, message: 'Work Experience Updated' });
      }
    }
    catch (error) {
      console.log("Error adding work experience: ", error);
    }
  }

  const [collapsedStates, setCollapsedStates] = useState({});

  const toggleCollapse = (id) => {
    setCollapsedStates((prevStates) => ({
      ...prevStates,
      [id]: !prevStates[id],
    }));
  };

  const isCollapsed = (id) => collapsedStates[id] ?? true;

  return (
    <>
      {/* ADD WORK SECTION */}
      <div className="flex flex-col max-md:px-3 pb-6 max-md:max-w-full pt-6 max-md:pt-0">
        {actionResponse.status && (
          <div className={`flex items-center self-center mb-6 max-md:mt-3 justify-between bg-white border border-gray-300 rounded-lg py-2 px-3 shadow-sm max-w-sm bg-green-100 text-green-600`}>
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
            <span className={`font-semibold mx-2 text-green-600`}>{actionResponse.message}</span>
            <div>
              <FaCheckCircle size={20} />
            </div>
          </div>
        )}
        <div className="flex justify-between gap-4 text-base tracking-tight max-md:flex-wrap">
          <div className="flex flex-col text-base tracking-tight max-md:flex-wrap">
            <div className="leading-[110%] text-base font-bold mb-2">
              {action} Work Experience
            </div>
            <p className="text-base tracking-tight leading-4 text-neutral-400 max-md:max-w-full">Describe your past jobs, responsibilities, and achievements. Highlight the skills and experiences that helped you grow.</p>
          </div>
        </div>

        {/* ADD company logo */}
        <div className="mt-6 max-md:max-w-full ">
          <div className="flex flex-col gap-5 max-md:gap-0">
            <div className="flex relative flex-col w-full max-md:ml-0 max-md:w-full gap-3">
              <div className="text-base tracking-tight leading-4 font-normal">
                Company Logo
              </div>
              <div className="flex flex-col w-[15%] max-[425px]:w-[25%] aspect-square h-auto grow overflow-hidden justify-center text-sm leading-4 text-center rounded-lg border border-solid border-neutral-300 text-stone-300 max-md:mt-0">
                {companyLogoPreview ? (
                  <img
                    alt="img"
                    loading="lazy"
                    src={companyLogoPreview}
                    className="self-center w-full"
                  />
                ) : (
                  <>
                    <div className="m-4 flex flex-col grow justify-center">
                      <img
                        alt="img"
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/176be2ea66493ab805bd3d9e5c7cd1f706a0de3766487bde8e82a9332aba88cd?"
                        className="self-center w-11 aspect-[0.95]"
                      />
                      <div>Company Logo</div>
                    </div>
                  </>
                )}
              </div>
              <input
                type="file"
                value=""
                onChange={handleCompanyLogoUpload}
                className="opacity-0  absolute top-0 left-0 w-full h-full cursor-pointer"
              />
            </div>

            {/* ---------------- */}
            <div className="flex flex-col w-full max-md:ml-0 max-md:w-full">
              <div className="flex flex-col w-full justify-center self-stretch my-auto text-base leading-5 placeholder-text-stone-300 max-md:mt-5 max-md:max-w-full">
                <div className="flex mt-2 max-[425px]:mt-0 flex-col w-full gap-3 max-md:flex-wrap max-md:max-w-full">
                  <div className="text-base tracking-tight leading-4 font-normal max-md:max-w-full">
                    Company
                  </div>
                  <input
                    placeholder="Name of Company"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    className="justify-center p-4 rounded-lg border border-solid border-neutral-300 w-full max-md:max-w-full"
                  />
                </div>

                <div className="flex gap-3 w-full items-center mt-6 max-[425px]:mt-4 max-md:flex-wrap max-md:max-w-full">
                  <div className="flex gap-3 w-full flex-col max-[425px]:w-full">
                    <div className="text-base tracking-tight leading-4 font-normal max-md:max-w-full">
                      Position
                    </div>
                    <input
                      placeholder="Position"
                      value={position}
                      onChange={(e) => setPosition(e.target.value)}
                      className="justify-center p-3 rounded-lg border border-solid border-neutral-300 max-md:max-w-full"
                    />
                  </div>
                  <div className="flex gap-3 w-full flex-col max-[425px]:w-full">
                    <div className="text-base tracking-tight leading-4 font-normal max-md:max-w-full">
                      Date Started
                    </div>
                    <input
                      type="date"
                      value={dateStarted}
                      onChange={(e) => setDateStarted(e.target.value)}
                      placeholder="Date Started"
                      className="justify-center p-3 rounded-lg border border-solid border-neutral-300 max-md:max-w-full"
                    />
                  </div>

                  <div className="flex flex-col w-full gap-3 max-[425px]:w-full">
                    <div className="text-base tracking-tight leading-4 font-normal max-md:max-w-full">
                      Date Ended
                    </div>
                    <input
                      type="date"
                      value={dateEnded}
                      onChange={(e) => setDateEnded(e.target.value)}
                      placeholder="Date Ended"
                      className="justify-center p-3 rounded-lg border border-solid border-neutral-300 max-md:max-w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 max-[425px]:mt-6 text-base tracking-tight leading-4 font-normal max-md:max-w-full">
          Work Arrangement
        </div>
        <div
          className="flex gap-5 pr-2 mt-3 text-base leading-5 
        whitespace-nowrap text-stone-300 max-md:flex-wrap max-md:pr-5"
        >
          <div
            className="flex gap-2 py-0.5 cursor-pointer"
            onClick={() => setWorkArrangement("Freelance")}
          >
            <div
              className={`shrink-0 w-4 h-4 rounded-md ${workArrangement === "Freelance" ? "bg-blue-900" : "bg-zinc-300"
                }`}
            />
            <div
              className={`my-auto ${workArrangement === "Freelance"
                ? "font-bold text-devRegBlack"
                : "font-normal text-stone-300"
                }`}
            >
              Freelance
            </div>
          </div>
          <div
            className="flex gap-2 py-0.5 cursor-pointer "
            onClick={() => setWorkArrangement("Fulltime")}
          >
            <div
              className={`shrink-0 w-4 h-4  rounded-md ${workArrangement === "Fulltime" ? "bg-blue-900" : "bg-zinc-300"
                }`}
            />
            <div
              className={`my-auto ${workArrangement === "Fulltime"
                ? "font-bold text-devRegBlack"
                : "font-normal text-stone-300"
                }`}
            >
              Fulltime
            </div>
          </div>
          <div
            className="flex gap-2 py-0.5 cursor-pointer "
            onClick={() => setWorkArrangement("Part-time")}
          >
            <div
              className={`shrink-0 w-4 h-4  rounded-md ${workArrangement === "Part-time" ? "bg-blue-900" : "bg-zinc-300"
                }`}
            />
            <div
              className={`my-auto ${workArrangement === "Part-time"
                ? "font-bold text-devRegBlack"
                : "font-normal text-stone-300"
                }`}
            >
              Part-time
            </div>
          </div>
          <div
            className="flex gap-2 py-0.5 cursor-pointer "
            onClick={() => setWorkArrangement("Contract")}
          >
            <div
              className={`shrink-0 w-4 h-4  rounded-md ${workArrangement === "Contract" ? "bg-blue-900" : "bg-zinc-300"
                }`}
            />
            <div
              className={`my-auto ${workArrangement === "Contract"
                ? "font-bold text-devRegBlack"
                : "font-normal text-stone-300"
                }`}
            >
              Contract
            </div>
          </div>
          <div
            className="flex gap-2 py-0.5 cursor-pointer "
            onClick={() => setWorkArrangement("Others")}
          >
            <div
              className={`shrink-0 w-4 h-4 rounded-md ${workArrangement === "Others" ? "bg-blue-900" : "bg-zinc-300"
                }`}
            />
            <div
              className={`my-auto ${workArrangement === "Others"
                ? "font-bold text-devRegBlack"
                : "font-normal text-stone-300"
                }`}
            >
              Others
            </div>
          </div>
        </div>
        <div className="mt-6 max-[425px]:mt-6 text-base tracking-tight leading-4 font-normal max-md:max-w-full">
          Job Description
        </div>
        <textarea
          placeholder="Responsibilities"
          value={jobDescription}
          onChange={(e) => setJobDescription(e.target.value)}
          className="justify-center items-start px-4 pt-4 pb-14 mt-3 text-base leading-5 rounded-lg border border-solid border-neutral-300 placeholder-text-stone-300 max-md:pr-5 max-md:max-w-full resize-none"
        />

        <hr className="my-6" />

        <div className="flex max-[425px]:mt-4 w-full justify-between items-center self-start text-base tracking-tight leading-4">
          <div className="font-normal">Add Projects / Portfolio</div>
          <button onClick={addProjectContainer} className="font-normal text-sky-600 px-3 py-2 bg-sky-100 rounded-full">
            + Add More
          </button>
        </div>

        {/* ADD MORE WORK FORM */}
        {addProjects.map((addProject, index) => (
          <div key={index} className="mt-3 max-md:max-w-full">
            <div className="flex flex-col gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex w-full max-md:ml-0 max-md:w-full">
                <div className="flex grow gap-4 max-[425px]:gap-2 mt-6 text-base leading-5 placeholder-text-stone-300 max-md:mt-4 max-[425px]:flex-col">
                  <input
                    placeholder="Project Title"
                    value={addProject.title}
                    onChange={(e) => handleInputChangeProjectContainer(index, "title", e.target.value)}
                    className="justify-center w-full items-start p-4 whitespace-nowrap rounded-lg border border-solid border-neutral-300 max-md:pr-5"
                  />
                  <input
                    placeholder="Category"
                    value={addProject.category}
                    onChange={(e) => handleInputChangeProjectContainer(index, "category", e.target.value)}
                    className="justify-center w-full items-start p-4 whitespace-nowrap rounded-lg border border-solid border-neutral-300 max-md:pr-5"
                  />
                </div>
              </div>
              <div className="overflow-hidden rounded-lg flex flex-col w-full max-md:ml-0 max-md:w-full relative">
                {addProject.thumbnail_path ? (
                  <img
                    alt=""
                    className="w-full aspect-[1.89]"
                    src={addProject.thumbnail_path}
                  />
                ) : (
                  <>
                    <div className="grow flex flex-col text-neutral-700 font-bold justify-center items-center self-stretch py-8 text-lg leading-6 text-center rounded-lg border border-dashed bg-gray-100 border-neutral-600 max-md:px-5 max-md:mt-4">
                      Drag or Upload images here
                      <br />
                      <span className="text-base font-normal text-neutral-400">Add image<br />
                        (Image size: 1024px x 687px) PNG, JPG up to 2MB</span>
                      <span className="text-base font-bold text-sky-500">Upload</span>
                    </div>
                    <input
                      type="file"
                      value=""
                      onChange={(e) => handleFileChangeProjectContainer(index, e.target.files[0])}
                      className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
        <div className="w-full justify-end flex py-2 mt-10 max-w-full text-base font-semibold text-white w-[309px] max-md:pl-5 max-[425px]:w-full max-[425px]:px-0">
          <button
            className="justify-center whitespace-nowrap px-6 py-3 bg-devRegDarkBlue rounded-full max-md:px-5 w-[230px] h-14 max-md:ml-2 max-[425px]:w-full max-[425px]:ml-0"
            onClick={() => { action === 'Add' ? handleAddWorkHistory() : handleSaveUpdateWorkHistory() }}
            disabled={isLoading.adding}
          >
            {isLoading.adding ? (
              <svg
                className="animate-spin h-5 w-5 text-white mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              `${action} Work Experience`
            )}
          </button>
        </div>

        <hr className="my-7" />

      </div>
      {/* WORK PREVIEW */}
      <div className="flex flex-col mt-4 max-md:max-w-full pb-[250px]">
        <div className="text-3xl font-semibold leading-10 mb-7 text-neutral-800 max-md:max-w-full">
          My Work History
        </div>

        {!isLoading.fetching ? (
          workHistory.length > 0 ? (
            workHistory.map((work, index) => (
              <>
                <div
                  key={index}
                  className="flex gap-3 items-center justify-between w-full max-md:flex-wrap max-md:max-w-full"
                >
                  <div className="flex gap-2">
                    {/* logo of work */}
                    <img
                      alt="img"
                      loading="lazy"
                      srcSet={work.company_logo}
                      className="shrink-0 aspect-square w-[55px]"
                    />
                    {/* work header */}
                    <div className="flex flex-col">
                      <div className="flex gap-2">
                        {/* POSITION */}
                        <div className="text-lg font-bold leading-7 text-devRegDarkBlue">
                          {work.position}
                        </div>

                        {/* TYPE OF WORK */}
                        <div className="justify-center py-1 px-3 text-xs text-sky-600 border border-sky-600 border-solid bg-sky-600 bg-opacity-10 rounded-[100px]">
                          {work.work_arrangement}
                        </div>
                      </div>
                      {/* Company and date joined */}
                      <div className="mt-1 text-base leading-6 text-neutral-600">
                        {work.company_name} | {formatDate(work.date_started)} - {formatDate(work.date_ended)}
                      </div>
                    </div>
                  </div>

                  {/* actions in this section */}
                  <div className="flex gap-2 text-lg font-medium text-blue-900 items-center">
                    <div>
                      <button
                        onClick={() => {
                          updatingId === work.id ? handleCancelUpdateWorkHistory() : handleUpdateWorkHistory(work);
                        }}
                      >
                        {updatingId === work.id ? 'Cancel' : 'Edit'}
                      </button>
                    </div>
                    <div>|</div>
                    <div>
                      {isDeleting.id === work.id && isDeleting.deleted === null && (
                        <div className="w-[25px] h-[25px] border-2 border-t-transparent border-[#1c3775] rounded-full animate-spin"></div>
                      )}
                      {isDeleting.id === work.id && isDeleting.deleted === true && (
                        <svg width="25" height="25" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.9484 20.8869C9.46389 22.124 10.6735 23.911 11.4639 24.6498C12.8556 26.0072 15.9278 29.0106 17.0824 30.1653C18.2371 31.3199 19.6598 30.6464 20.2268 30.1653L33.1134 17.2787C33.5945 16.7976 34.268 15.4746 33.1134 14.0313C31.9587 12.588 30.4329 13.4299 29.8144 14.0313L18.5257 25.3199L15.0721 21.8663C14.0927 20.8869 12.8041 19.3405 10.9484 20.8869Z" fill="#00A018" />
                          <circle cx="22" cy="22" r="20" stroke="#00A018" strokeWidth="4" />
                        </svg>
                      )}
                      {isDeleting.id !== work.id && (
                        <button className="text-red-500" onClick={() => handleDeleteWorkHistory(work.id)}>Delete</button>
                      )}
                    </div>
                    {/* Collapse/Expand Arrow */}
                    <button onClick={() => toggleCollapse(work.id)} className="ml-2 text-gray-600">
                      {isCollapsed(work.id) ? <FaChevronDown /> : <FaChevronUp />}
                    </button>
                  </div>
                </div>

                {!isCollapsed(work.id) && (
                  <>
                    {/* DESCRIPTION OF WORK */}
                    <div className="mt-4 text-base leading-6 text-neutral-600 max-md:max-w-full">
                      {work.description}
                    </div>

                    <div className="mt-4 text-base font-bold leading-6 text-neutral-600 max-md:max-w-full">
                      Projects
                    </div>

                    {/* PROJECTS showcase */}
                    <div className="mt-4 max-md:max-w-full">
                      {/* project in this company container main */}
                      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        {/* project loop here */}
                        {work.projects.map((project, index) => (
                          <div
                            key={index}
                            className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full"
                          >
                            <div className="flex flex-col grow text-neutral-800 max-md:mt-2.5">
                              <img
                                alt="img"
                                loading="lazy"
                                srcSet={project.thumbnail_path}
                                className="w-full aspect-[1.89]"
                              />
                              <div className="mt-2 text-base font-bold leading-6">
                                {project.title}
                              </div>
                              <div className="text-xs">{project.description}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
                <hr className="my-7" />
              </>
            ))
          ) : (
            <div className="text-center text-neutral-600 w-full">
              No Works History added yet.
            </div>
          )
        ) : (
          <>
            <div className="w-full flex justify-center min-h-[200px]">
              <Loading />
            </div>
          </>
        )}

        {/* end  */}
      </div>
    </>
  );
}

export default WorkHistory;
