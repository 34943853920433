const items = [
  {
    title: "What is devreg.org?",
    content: (
      <div>
        <p className="mb-4">
          Devreg.org is a platform designed to verify the identities of professionals and individuals across various roles and job types. This platform enables anyone to confirm the identity of a person they are interacting with, whether for professional, personal, or transactional purposes. Our service is especially useful for those seeking to verify the identity of a tradesperson, a professional, or even someone who has met online. By linking profiles on fullidcheck to register.dev, users can send a verification request that prompts the recipient to confirm their identity, report spam, or indicate a stolen ID. This ensures that the person you are dealing with is registered and trusted, providing peace of mind and preventing various types of scams and fraudulent activities, including:
        </p>

        <h3 className="font-bold mt-4 mb-2">Identity Verification Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Identity Theft:</strong> Preventing unauthorized use of personal information to impersonate someone else.</li>
          <li><strong className="text-gray-500 font-semibold">Fake IDs:</strong> Detecting and rejecting forged or counterfeit identification documents.</li>
          <li><strong className="text-gray-500 font-semibold">Phishing Scams:</strong> Protecting against attempts to obtain sensitive information through deceptive emails or websites.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Employment and Freelancer Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Job Offers:</strong> Ensuring job listings are legitimate and employers are verified.</li>
          <li><strong className="text-gray-500 font-semibold">Non-existent Freelancers:</strong> Verifying the identity of remote workers to ensure they are real and qualified.</li>
          <li><strong className="text-gray-500 font-semibold">Fraudulent Resumes:</strong> Checking the authenticity of qualifications and work history.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Financial and Investment Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Ponzi Schemes:</strong> Identifying and preventing fraudulent investment operations.</li>
          <li><strong className="text-gray-500 font-semibold">Advance Fee Fraud:</strong> Ensuring legitimacy before fees for services or opportunities are paid upfront.</li>
          <li><strong className="text-gray-500 font-semibold">Fake Investment Opportunities:</strong> Ensuring legitimacy before fees for services or opportunities are paid upfront.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Online Dating and Romance Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Catfishing:</strong> Preventing individuals from creating fake profiles to deceive others for romantic or financial gain.</li>
          <li><strong className="text-gray-500 font-semibold">Romance Scams:</strong> Verifying identities to ensure genuine interactions on dating sites.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">E-commerce and Online Marketplace Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Sellers:</strong> Ensuring sellers on online marketplaces are legitimate.</li>
          <li><strong className="text-gray-500 font-semibold">Non-delivery of Goods:</strong> Ensuring transactions for goods are legitimate before fees are paid upfront.</li>
          <li><strong className="text-gray-500 font-semibold">Counterfeit Products:</strong> Ensuring the authenticity of products sold online.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Rental and Real Estate Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Rental Listings:</strong> Verifying landlords and property listings to prevent fraudulent rental offers.</li>
          <li><strong className="text-gray-500 font-semibold">Real Estate Fraud:</strong> Ensuring real estate transactions involve legitimate properties and owners.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Financial Services Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Loan Scams:</strong> Verifying lenders to prevent fake loan offers.</li>
          <li><strong className="text-gray-500 font-semibold">Online Lending Scams:</strong> Ensuring online lending platforms are legitimate.</li>
          <li><strong className="text-gray-500 font-semibold">Credit Card Fraud:</strong> Preventing unauthorized use of credit card information.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Healthcare and Insurance Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Medical Identity Theft:</strong> Protecting personal health information from being fraudulently used.</li>
          <li><strong className="text-gray-500 font-semibold">Fake Insurance Policies:</strong> Verifying insurance providers and policies to prevent fraud.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Education and Certification Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Diploma Mills:</strong> Ensuring educational institutions and their credentials are legitimate.</li>
          <li><strong className="text-gray-500 font-semibold">Fake Certifications:</strong> Verifying professional certifications and licenses.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Crowdfunding and Charity Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Charities:</strong> Ensuring charity organizations and fundraising campaigns are legitimate.</li>
          <li><strong className="text-gray-500 font-semibold">Crowdfunding Fraud:</strong> Verifying the authenticity of crowdfunding projects and their creators.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Travel and Accommodation Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Travel Agencies:</strong> Verifying travel agencies and their offers.</li>
          <li><strong className="text-gray-500 font-semibold">Accommodation Scams:</strong> Ensuring listings on accommodation platforms are genuine.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Subscription and Membership Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Subscription Services:</strong> Verifying the legitimacy of subscription-based services.</li>
          <li><strong className="text-gray-500 font-semibold">Membership Scams:</strong> Ensuring membership organizations and their offers are genuine.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Vehicle Rental and Sharing Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Car Rentals:</strong> Verifying vehicle rental companies and their offers.</li>
          <li><strong className="text-gray-500 font-semibold">Car Sharing Fraud:</strong> Ensuring car-sharing services and participants are legitimate.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Second-hand and Used Goods Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Listings:</strong> Verifying sellers and items listed on second-hand marketplaces.</li>
          <li><strong className="text-gray-500 font-semibold">Non-delivery of Second-hand Goods:</strong> Ensuring transactions for used goods are legitimate.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Professional Services Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Professionals:</strong> Verifying the identity and credentials of professionals offering services.</li>
          <li><strong className="text-gray-500 font-semibold">Unlicensed Tradespersons:</strong> Ensuring tradespersons have the necessary licenses and qualifications.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Cybersecurity Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Hacking and Phishing:</strong> Protecting users from cyber threats and ensuring secure interactions.</li>
          <li><strong className="text-gray-500 font-semibold">Malware and Ransomware:</strong> Preventing the spread of malicious software through verified platforms.</li>
        </ul>
      </div>
    ),
  },
  {
    title: "How do I register on devreg.org?",
    content: "lorem ipsum",
  },
  {
    title: "What is the cost of registration?",
    content: "lorem ipsum",
  },
  {
    title: "What happens if I don't pay the annual membership fee?",
    content: "lorem ipsum",
  },
  {
    title: "What documents do I need to provide during registration?",
    content: "lorem ipsum",
  },
  {
    title: "How often do I need to update my profile photo?",
    content: "lorem ipsum",
  },
  {
    title: "How do clients verify my identity?",
    content: "lorem ipsum",
  },
  {
    title: "How does the referral program work?",
    content: "lorem ipsum",
  },
  {
    title: "What is the badge draw, and how can I participate?",
    content: "lorem ipsum",
  },
  {
    title: "How is my personal information protected?",
    content: "lorem ipsum",
  },
  {
    title: "Can I update my information after registration?",
    content: "lorem ipsum",
  },
  {
    title: "What should I do if I encounter a problem during registration?",
    content: "lorem ipsum",
  },
  {
    title: "How long does the verification process take?",
    content: "lorem ipsum",
  },
  {
    title: "What happens if a complaint is lodged against me?",
    content: "lorem ipsum",
  },
  {
    title: 'Can I deactivate or delete my account?',
    content: "lorem ipsum",
  },
  {
    title: "What types of jobs and professions can be verified on devreg.org?",
    content: "lorem ipsum",
  },
  {
    title: 'How does devreg.org ensure the authenticity of the verification process?',
    content: "lorem ipsum",
  },
  {
    title: 'Is my information shared with other members or third parties?',
    content: "lorem ipsum",
  },
  {
    title: 'What if I lose access to my account?',
    content: "lorem ipsum",
  },
  {
    title: 'How do I participate in the referral program?',
    content: "lorem ipsum",
  },
  {
    title: 'What should I do if I suspect someone on the platform is a scammer?',
    content: "lorem ipsum",
  },
  {
    title: 'Can I upgrade my membership after registration?',
    content: "lorem ipsum",
  },
  {
    title: 'How can I ensure my profile stands out to clients?',
    content: "lorem ipsum",
  },
  {
    title: 'Are there any restrictions on who can join devreg.org?',
    content: "lorem ipsum",
  },
  {
    title: 'How does devreg.org handle disputes between clients and members?',
    content: "lorem ipsum",
  },
  {
    title: 'Why do you ask for next of kin information?',
    content: "lorem ipsum",
  },
  {
    title: 'How do I contact customer support?',
    content: "lorem ipsum",
  },
  {
    title: 'What if my verification is unsuccessful?',
    content: "lorem ipsum",
  },
  {
    title: 'Is devreg.org worldwide?',
    content: "lorem ipsum",
  },
  {
    title: 'How can I provide feedback about my experience with devreg.org?',
    content: "lorem ipsum",
  },
  {
    title: 'What are the benefits of being a verified member on devreg.org?',
    content: "lorem ipsum",
  },
  {
    title: 'How do I update my next of kin information?',
    content: "lorem ipsum",
  },
  {
    title: 'Are there any fees for updating my information?',
    content: "lorem ipsum",
  },
  {
    title: 'How do I know if my documents have been securely processed?',
    content: "lorem ipsum",
  },
  {
    title: 'What measures does devreg.org take to prevent fraud?',
    content: "lorem ipsum",
  },
  {
    title: 'What should I expect during the interview?',
    content: "lorem ipsum",
  },
  {
    title: 'What if law enforcement wants access to the server for information on the members?',
    content: "lorem ipsum",
  },
  {
    title: 'Can I use devreg.org for personal identity verification?',
    content: "lorem ipsum",
  },
  {
    title: 'What should I do if I forget my login credentials?',
    content: "lorem ipsum",
  },
  {
    title: 'How can I ensure my profile is not flagged for inactivity?',
    content: "lorem ipsum",
  },
  {
    title: 'Are there any guidelines for the profile photo I upload?',
    content: "lorem ipsum",
  },
  {
    title: 'What happens if I provide false information during registration?',
    content: "lorem ipsum",
  },
  {
    title: 'Can I refer friends or family to join devreg.org?',
    content: "lorem ipsum",
  },
  {
    title: 'How do I participate in community discussions or forums on devreg.org?',
    content: "lorem ipsum",
  },
  {
    title: 'What if I encounter a technical issue on the website?',
    content: "lorem ipsum",
  },
  {
    title: 'Can I suggest new features or improvements for devreg.org?',
    content: "lorem ipsum",
  },
];

export { items };
