import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CiBellOn } from "react-icons/ci";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosMenu } from "react-icons/io";
import SidebarMobile from "./SidebarMobile";
// import SidebarDashboard from "./SidebarDashboard";

function DashboardNavbar() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const navigate = useNavigate();
  const [toggleDashboardTab, setToggleDashboardTab] = useState(false);

  const avatar = user.profile_photo_path || "/default-avatar.png";

  useEffect(() => {
    if (toggleDashboardTab) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [toggleDashboardTab]);
  return (
    <>
      {toggleDashboardTab && (
        <div
          className=" w-[100vw] h-[100vh] bg-[#040e1d94] absolute z-[10] min-[768px]:hidden"
          onClick={() => setToggleDashboardTab(!toggleDashboardTab)}
        >
          <SidebarMobile />
        </div>
      )}
      <header className="max-w-[1366px] m-auto flex justify-between items-center max-md:flex-wrap max-md:max-w-full max-sm:px-2 max-sm:py-2 px-8 py-3">
        <div
          className="hamburger-menu px-1 py-1 bg-[#F2F7FF] rounded-xl max-sm:text-[30px] max-md:text-[40px] cursor-pointer min-[768px]:hidden"
          onClick={() => setToggleDashboardTab(!toggleDashboardTab)}
        >
          <IoIosMenu />
        </div>

        <img
          loading="lazy"
          src={"/loginLogo.png"}
          alt="Company logo"
          className=" my-auto max-w-full w-[220px] cursor-pointer max-sm:w-[170px] max-[378px]:w-[120px]"
          onClick={() => {
            navigate("/dashboard/messages");
            window.scrollTo(0, 0);
          }}
        />
        <div className="flex item-center justify-center gap-3 px-2">
          <div className="notif-container my-auto relative max-sm:text-[23px] text-[28px]">
            <CiBellOn className="cursor-pointer" />
            <div className="notif-dot bg-[#2AD2C9] w-2 h-2 rounded-full absolute top-1 right-[2px]"></div>
          </div>
          <div className="profile-container flex item-center justify-center gap-3">
            <img
              alt="Avatar"
              className="profile-picture w-14 h-14 max-md:w-12 max-md:h-12 max-sm:w-10 max-sm:h-10 rounded-full hover:border-2 hover:border-[#2AD2C9] cursor-pointer"
              src={avatar}
            />
            <div className="name-credit flex items-start justify-center flex-col leading-5 max-md:hidden">
              <div className="name flex items-center justify-center font-bold gap-1 text-[#4A4A4A]">
                <div className="first-name">{user.first_name}</div>
              </div>
              <div className="Credit text-sm">$ USD Credit</div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default DashboardNavbar;
