import React from "react";
import { FaEdit } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { TbBriefcase } from "react-icons/tb";

function BannerProfile() {
  const user = JSON.parse(localStorage.getItem('user'));

  const ProfilePicture = user.profile_photo_path || "/default-avatar.png";
  const profileRegisteredIcon = "/profileRegisteredIcon.png";
  const navigation = useNavigate();
  return (
    <div className=" flex justify-between bg-white border border-gray-200 px-10 max-[1024px]:px-4 max-sm:px-2  max-[962px]:px-6 py-3 rounded-2xl">
      <div className="pic-name-container flex item-center gap-2  max-sm:gap-0">
        <img
          alt="img"
          src={ProfilePicture}
          className="profile-pic w-16 h-16 bg-blue-400  rounded-full border-[2px] border-white my-auto max-sm:my-1 max-sm:w-14 max-sm:px-6 max-sm:h-14"
        />
        <div className="name-job flex flex-col justify-center gap-1.5 leading-7  max-sm:pl-2">
          <div className="flex gap-3 max-sm:flex-col max-sm:gap-0  ">
            <div className="name text-[25px] font-semibold max-[1024px]:text-[22px] text-[#1C3775] max-sm:text-[20px] max-sm:leading-6">
              {user.fullname}
            </div>
            <div className="flex bg-blue-900 text-white items-center max-sm:h-6 max-sm:w-[40%] max-sm:mt-1 whitespace-nowrap justify-center gap-1 rounded-full px-3 cursor-pointer">
              <FaEdit size={12} />
              <div
                className="job max-sm:text-[9px] text-[12px] cursor-pointer"
                onClick={() =>
                  navigation("/dashboard/edit-profile?tab=overview")
                }
              >
                Edit Profile
              </div>
            </div>
          </div>
          <div className="flex text-[#0082BA] gap-3 max-sm:gap-1 text-md max-[962px]:text-md max-sm:text-[11px]">
            <div className="job flex gap-2 items-center">
              <TbBriefcase/>
              {user.profession}
              </div>
            <div className="location flex items-center gap-1 justify-center">
              <div>
                <FaLocationDot/>
              </div>
              <div>{user.address?.state}, {user.address?.country}</div>
            </div>
          </div>
        </div>
      </div>
      <img
        src={profileRegisteredIcon}
        alt="profileRegisteredIcon "
        className="max-w-[120px] max-h-[70x] max-[1024px]:w-[110px] max-[962px]:h-[70px] max-sm:w-[80px] max-sm:h-[50px]"
      />
    </div>
  );
}

export default BannerProfile;