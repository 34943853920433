import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import MessagesMain from "../pages/Dashboard/Messages/MessagesMain";
import DashboardMain from "../pages/Dashboard/MainDashboard/DashboardMain";
import DashboardNavbar from "../pages/Dashboard/components/DashboardNavbar";
import SidebarDashboard from "../pages/Dashboard/components/SidebarDashboard";
import FooterDashboard from "../pages/Dashboard/components/FooterDashboard";
import EarningsAndReferrals from "../pages/Dashboard/EarningsAndReferrals/EarningsAndReferrals";
import Certificate from "../pages/Dashboard/Certificate/Certificate";
import EditProfile from "../pages/Dashboard/EditProfile/EditProfileMain";
import ProfileMain from "../pages/Dashboard/EditProfile/pages/ProfileMain";
import Forum from "../pages/Dashboard/Forum/pages/MainForum/MainForum";
import TopViewed from "../pages/Dashboard/Forum/pages/TopViewed/TopViewedMain";
import SavedPosts from "../pages/Dashboard/Forum/pages/SavedPosts/SavedPostsMain";
import NewPost from "../pages/Dashboard/Forum/pages/NewPost/NewPostMain";
import AccountSettingsMain from "../pages/Dashboard/AccountSetting/AccountSettingsMain";
import ChangePassword from "../pages/Dashboard/AccountSetting/ChangePassword/ChangePassword";
import ChangeNumber from "../pages/Dashboard/AccountSetting/ChangeNumber/ChangeNumber";
import ChangeEmail from "../pages/Dashboard/AccountSetting/ChangeEmail/ChangeEmail";
import Help from "../pages/Dashboard/AccountSetting/Help";
import CoinProfileMain from "../pages/CoinProfile/CoinProfileMain/CoinProfileMain";
import CoinPageMain from "../pages/CoinProfile/CoinPage/CoinPageMain";
import RegisterCoinMain from "../pages/CoinProfile/RegisterCoin/RegisterCoinMain";
import EditCoinMain from "../pages/CoinProfile/EditCoinDetails/EditCoinMain";
import AssignNewDev from "../pages/CoinProfile/AssignNewHeadDev/AssignNewDev";
import RemoveCoinDevMain from "../pages/CoinProfile/RemoveCoinDev/RemoveCoinDevMain";
import ResignAsHeadDevMain from "../pages/CoinProfile/ResignAsHeadDev/ResignAsHeadDevMain";
import FileComplaintMain from "../pages/CoinProfile/FileAComplaint/FileComplaintMain";
import ResignAsCoinDev from "../pages/CoinProfile/ResignAsCoinDev/ResignAsCoinDev";

function LoggedIn() {
  const location = useLocation();
  const [hideDashboard, setHideDashboard] = useState(false);

  useEffect(() => {
    if (location.pathname === "/dashboard/profile") {
      setHideDashboard(true);
    } else {
      setHideDashboard(false);
    }
  }, [location]);
  return (
    <>
      {!hideDashboard && <DashboardNavbar />}
      <div className="flex max-w-main  m-auto">
        {!hideDashboard && <SidebarDashboard />}
        <div className="w-full pr-5 pl-10  max-sm:pr-4 max-sm:p-4">
          <Routes>
            <Route path="/dashboard" element={<DashboardMain />} />
            <Route path="/dashboard/messages" element={<MessagesMain />} />
            <Route
              path="/dashboard/earnings-and-referrals"
              element={<EarningsAndReferrals />}
            />

            <Route path="/dashboard/certificate" element={<Certificate />} />
            <Route path="/dashboard/profile" element={<ProfileMain />} />
            <Route path="/dashboard/forum" element={<Forum />} />
            <Route path="/dashboard/forum/post/:id" element={<TopViewed />} />
            <Route path="/dashboard/forum/saved-posts" element={<SavedPosts />} />
            <Route path="/dashboard/forum/new-post" element={<NewPost />} />
            <Route path="/dashboard/edit-profile" element={<EditProfile />} />
            <Route path="/dashboard/help" element={<Help />} />
            <Route
              path="/dashboard/account-settings/change-number"
              element={<ChangeNumber />}
            />
            <Route
              path="/dashboard/account-settings/change-password"
              element={<ChangePassword />}
            />
            <Route
              path="/dashboard/account-settings/change-email"
              element={<ChangeEmail />}
            />
            <Route
              path="/dashboard/account-settings"
              element={<AccountSettingsMain />}
            />
            {/* COIN PROFILE  */}
            <Route
              path="/dashboard/coin-profile"
              element={<CoinProfileMain />}
            />
            <Route
              path="/dashboard/coin-profile/coin/:ticker"
              element={<CoinPageMain />}
            />

            <Route
              path="/dashboard/coin-profile/register-coin"
              element={<RegisterCoinMain />}
            />
            <Route
              path="/dashboard/coin-profile/coin/:ticker/edit-coin"
              element={<EditCoinMain />}
            />
            <Route
              path="/dashboard/coin-profile/coin/:ticker/assign-new-head-developer"
              element={<AssignNewDev />}
            />
            <Route
              path="/dashboard/coin-profile/coin/:ticker/remove-coin-developer"
              element={<RemoveCoinDevMain />}
            />
            <Route
              path="/dashboard/coin-profile/coin/:ticker/resign-as-head-developer"
              element={<ResignAsHeadDevMain />}
            />
            <Route
              path="/dashboard/coin-profile/coin/:ticker/file-a-complaint"
              element={<FileComplaintMain />}
            />
            <Route
              path="/dashboard/coin-profile/coin/:ticker/resign-as-coin-developer"
              element={<ResignAsCoinDev />}
            />
          </Routes>
        </div>
      </div>
      {!hideDashboard && <FooterDashboard />}
    </>
  );
}

export default LoggedIn;
