import React, { useEffect, useState } from "react";
import { RiDashboardLine } from "react-icons/ri";
import { BiMessageSquare } from "react-icons/bi";
import { IoPersonOutline, IoTrophyOutline } from "react-icons/io5";
import { TbMessage } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsLoggedIn } from "../../../actions";
import { PiCoinVerticalThin } from "react-icons/pi";
import { 
  getTheRegisterMessages,
  getDevregMessages,
  getAdminMessages,
  logout,
} from "../../../api/apiCall.js";

function SidebarMobile() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const earningsIcon = "/EarningsIcon.png";
  //   const { logout } = useAuth();

  const handleLogout = () => {
    // logout();
    dispatch(setIsLoggedIn(false));
    localStorage.setItem("isLoggedIn", false);
    navigate("/");
    window.scrollTo(0, 0);
  };
  const [isActive, setIsActive] = useState("");
  const [showSecondNav, setShowSecondNav] = useState(false);
  const listTab = [
    {
      title: "Overview",
      link: "?tab=overview",
      isActive: "overview",
    },
    {
      title: "Advertisement/Features",
      link: "?tab=advertisement-features",
      isActive: "advertisement-features",
    },
    {
      title: "Language",
      link: "?tab=language",

      isActive: "language",
    },
    {
      title: "Work History",
      link: "?tab=work-history",
      isActive: "work-history",
    },
    {
      title: "Portfolio",
      link: "?tab=portfolio",
      isActive: "portfolio",
    },
    {
      title: "Skills and Expertise",
      link: "?tab=skills-and-expertise",
      isActive: "skills-and-expertise",
    },
    {
      title: "Education / Certificate",
      link: "?tab=education-certificate",
      isActive: "education-certificate",
    },
    {
      title: "Honor and Awards",
      link: "?tab=honor-and-awards",
      isActive: "honor-and-awards",
    },

    {
      title: "Preview Profile",
      link: "/dashboard/profile",
      isActive: "preview-profile",
    },
  ];

  const [activeTab, setActiveTab] = React.useState("");
  const [overview, setOverView] = React.useState("overview");
  const [markTheNav, setMarkTheNav] = React.useState(0);
  const location = useLocation();
  const [messageCount, setMessageCount] =  useState({theRegister: 0, devreg: 0, admin: 0})

  useEffect(() => {
    let intervalId;

    const init =  async() => {
      await getMessagesCount();

      intervalId = setInterval(() => {
        setMessageCount(JSON.parse(localStorage.getItem("messages_count")) || messageCount);
      }, 1000);
  
    }
    
    init();
    
    return () => clearInterval(intervalId);
    
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabParam = urlParams.get("tab");

    if (location.pathname === "/dashboard/earnings-and-referrals") {
      setMarkTheNav(0);
      setActiveTab("earnings");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/messages") {
      if (tabParam === "admin") {
        setMarkTheNav(3);
        setActiveTab("messages");
      }
      if (tabParam === "theregister") {
        setMarkTheNav(2);
        setActiveTab("messages");
      }
      if (tabParam === "fullcheckid") {
        setMarkTheNav(1);
        setActiveTab("messages");
      }
      setActiveTab("messages");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/account-settings/help") {
      setMarkTheNav(6);
      setActiveTab("messages");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/news") {
      setMarkTheNav(0);
      setActiveTab("news");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/forum") {
      setMarkTheNav(0);
      setActiveTab("forum");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/coin-profile") {
      setMarkTheNav(0);
      setActiveTab("coin");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/edit-profile") {
      setMarkTheNav(4);
      setShowSecondNav(false);
      setActiveTab("editProfile");
    } else if (location.pathname === "/dashboard/certificate") {
      setMarkTheNav(5);
      setShowSecondNav(false);

      setActiveTab("editProfile");
    } else if (location.pathname === "/dashboard/account-settings") {
      setMarkTheNav(6);
      setShowSecondNav(false);

      setActiveTab("accountSettings");
    } else if (location.pathname === "/dashboard/help") {
      setMarkTheNav(7);
      setShowSecondNav(false);
      setActiveTab("accountSettings");
    } else if (
      location.pathname === "/dashboard/account-settings/change-number"
    ) {
      setMarkTheNav(6);
      setShowSecondNav(false);
      setActiveTab("accountSettings");
    } else if (
      location.pathname === "/dashboard/account-settings/change-password"
    ) {
      setMarkTheNav(6);
      setShowSecondNav(false);
      setActiveTab("accountSettings");
    } else if (
      location.pathname === "/dashboard/account-settings/change-email"
    ) {
      setMarkTheNav(6);
      setShowSecondNav(false);
      setActiveTab("accountSettings");
    } else {
      setShowSecondNav(false);

      setMarkTheNav(0);
      setActiveTab("");
    }

    // Edit Profile

    if (tabParam === "overview") {
      setOverView("overview");
    }
    if (tabParam === "advertisement-features") {
      setOverView("advertisement-features");
    }
    if (tabParam === "language") {
      setOverView("language");
    }
    if (tabParam === "work-history") {
      setOverView("work-history");
    }
    if (tabParam === "portfolio") {
      setOverView("portfolio");
    }
    if (tabParam === "skills-and-expertise") {
      setOverView("skills-and-expertise");
    }
    if (tabParam === "education-certificate") {
      setOverView("education-certificate");
    }
    if (tabParam === "honor-and-awards") {
      setOverView("honor-and-awards");
    }
    if (tabParam === "preview-profile") {
      setOverView("preview-profile");
    }
  }, [location]);
  const handleClick = (event) => {
    event.stopPropagation();
    setShowSecondNav(true);
  };
  console.log(showSecondNav);

  const getMessagesCount = async () => {
    try {
      let data = {
        id: user.id,
        token: token
      }
      const [responseTheRegister, responseDevreg, responseAdmin] = await Promise.all([
        getTheRegisterMessages(data),
        getDevregMessages(data),
        getAdminMessages(data)
      ]);
      setMessageCount({
        theRegister: responseTheRegister.data.total,
        devreg: responseDevreg.data.total,
        admin: responseAdmin.data.total
      });
      const counts = {
        theRegister: responseTheRegister.data.total,
        devreg: responseDevreg.data.total,
        admin: responseAdmin.data.total
      }
      localStorage.setItem("messages_count", JSON.stringify(counts));
    } 
    catch (error) {
      console.error("Error fcounting messages:", error);
    }
    finally{

    }
  }

  return (
    <div className="flex justify-between overflow-hidden">
      <div
        className={`min-h-[100vh] relative ${
          showSecondNav ? "max-w-[270px]" : "max-w-[310px]"
        } bg-devRegDarkBlue py-2 min-[900px]:hidden overflow-y-auto`}
        style={{ maxHeight: '100vh' }}
      >
        <div className="title flex items-center gap-1 mb-2 px-5 py-3">
          <RiDashboardLine size={30} className="text-devRegGreen" />
          <div className="font-bold text-[25px] text-devRegGreen">Dashboard</div>
        </div>
        <div className="h-[0.8px] w-full bg-[#243D78] "></div>
        {/* navs */}
        <div className="dasboard-nav-cointainter">
          <div
            className={`title flex items-center gap-1 text-white text-lg py-4 px-5 cursor-pointer ${
              activeTab === "forum" ? "bg-[#0082ba]" : ""
            }`}
            onClick={() => navigate("/dashboard/forum")}
          >
            <TbMessage color="white" />
            <div
              className={`pl-1 ${
                activeTab === "forum" ? "font-extrabold" : "font-normal"
              }`}
            >
              Forum
            </div>
          </div>
          {/* Messages */}
          <div
            className={`title flex items-center gap-1 py-4 ${
              activeTab === "messages" ? "font-bold bg-[#0082ba]" : "font-normal"
            } text-lg py-2 cursor-pointer px-5`}
            onClick={() => {
              navigate("/dashboard/messages");
            }}
          >
            <BiMessageSquare color="white" />
            <div className="pl-1 text-white">Messages</div>
          </div>
          <div className="text-white">
            <div
              className={`flex items-center justify-between px-10 py-3 cursor-pointer ${
                markTheNav === 1 ? "text-white font-semibold bg-[#0082ba]" : "text-white"
              }`}
              onClick={() => {
                navigate("/dashboard/messages?tab=theRegister");
              }}
            >
              <div className="">The Register</div>
              <div className="">{messageCount.theRegister}</div>
            </div>
            <div className="h-[0.8px] w-full bg-[#243D78] "></div>
            <div
              className={`flex items-center justify-between px-10 py-3 cursor-pointer ${
                markTheNav === 2 ? "text-white font-semibold bg-[#0082ba]" : "font-normal"
              }`}
              onClick={() => {
                navigate("/dashboard/messages?tab=devreg");
              }}
            >
              <div className="">DevReg</div>
              <div className="">{messageCount.devreg}</div>
            </div>
            <div className="h-[0.8px] w-full bg-[#243D78] "></div>
            <div
              className={`flex items-center justify-between px-10 py-3 cursor-pointer ${
                markTheNav === 3 ? "text-white font-semibold bg-[#0082ba]" : "text-white"
              }`}
              onClick={() => {
                // Navigate to the messages route with the admin tab parameter
                navigate("/dashboard/messages?tab=admin");
              }}
            >
              <div className="">Admin</div>
              <div className="">{messageCount.admin}</div>
            </div>
          </div>
          <div className="h-[0.8px] w-full bg-[#243D78] "></div>
  
          {/* Earn and Refs */}
          <div
            className={`title flex items-center gap-1 text-white text-lg py-4 px-5 cursor-pointer ${
              activeTab === "earnings" ? "bg-[#0082ba]" : ""
            }`}
            onClick={() => navigate("/dashboard/earnings-and-referrals")}
          >
            <IoTrophyOutline color="white" />

            <div
              className={`pl-1 ${
                activeTab === "earnings" ? "font-extrabold" : "font-normal"
              }`}
            >
              Earnings & Referrals
            </div>
          </div>
  
          <div className="h-[0.8px] w-full bg-[#243D78] "></div>
  
          {/* Professional Profile */}
          <div className={`title flex items-center gap-1 text-white text-lg py-4 px-5 cursor-pointer ${
              activeTab === "editProfile" ? "bg-[#0082ba]" : ""
            }`}>
            <IoPersonOutline color="white" />
  
            <div
              className={`pl-1 ${
                activeTab === "editProfile" ? "font-extrabold" : "font-normal "
              }`}
              onClick={() => navigate("/dashboard/edit-profile")}
            >
              Professional Profile
            </div>
          </div>
          <div className="text-white">
            <div
              className={`flex items-center px-10 py-3 ${
                showSecondNav ? "text-white font-semibold bg-[#0082ba]" : "font-normal"
              }`}
              onClick={handleClick}
            >
              <div className="cursor-pointer">Edit Profile</div>
            </div>
            <div className="h-[0.8px] w-full bg-[#243D78] "></div>
            <div
              className={`flex items-center py-3 px-10 ${
                markTheNav === 5 ? "text-white font-semibold bg-[#0082ba]" : "text-white"
              }`}
              onClick={() => navigate("/dashboard/certificate")}
            >
              <div className="cursor-pointer">Cerificate</div>
            </div>
          </div>
          <div className="h-[0.8px] w-full bg-[#243D78] "></div>
  
          {/* Coins */}
          <div
            onClick={() => navigate("/dashboard/coin-profile")}
            className={`title flex items-center gap-1 text-white text-lg py-4 px-5 cursor-pointer ${
              activeTab === "coin" ? "bg-[#0082ba]" : ""
            }`}
          >
            <PiCoinVerticalThin color="white" />
  
            <div
              className={`${
                activeTab === "coin" ? "font-extrabold" : "font-normal"
              } pl-1 cursor-pointer`}
            >
              Coin Profile
            </div>
          </div>
  
          <div className="h-[0.8px] w-full bg-[#243D78] "></div>
  
          {/* Account Settings */}
          <div
            className={`title text-white flex items-center gap-1 text-lg py-3 cursor-pointer px-5 ${
              activeTab === "accountSettings" ? "font-extrabold bg-[#0082ba]" : "font-normal"
            }`}
            onClick={() => navigate("/dashboard/account-settings")}
          >
            <IoPersonOutline color="white" />
  
            <div className="pl-1 cursor-pointer ">Account Settings</div>
          </div>
          <div className="text-white">
            <div
              className={`flex items-center px-14 py-3 ${
                markTheNav === 6 ? "font-semibold bg-[#0082ba]" : "font-normal"
              }`}
            >
              <div
                className="cursor-pointer whitespace-nowrap"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Security Setting
              </div>
            </div>
            <div className="h-[0.8px] w-full bg-[#243D78] "></div>
            <div
              className={`flex items-center py-3 px-14 ${
                markTheNav === 7 ? "font-semibold bg-[#0082ba]" : "font-normal"
              } `}
            >
              <div
                className="cursor-pointer"
                onClick={() => navigate("/dashboard/help")}
              >
                Help
              </div>
            </div>
            <div className="h-[0.8px] w-full bg-[#243D78] "></div>
            <div
              className={`flex items-center py-3 px-14 ${
                markTheNav === 8 ? "text-white font-semibold bg-[#0082ba]" : "text-white"
              } `}
            >
              <div onClick={handleLogout} className="cursor-pointer">
                Log Out
              </div>
            </div>
          </div>
          <div className="h-[0.8px] w-full bg-[#243D78] "></div>
        </div>
      </div>
      {showSecondNav && (
        <div className="min-w-[200px] absolute pt-20 bg-[#F2F7FF] max-w-[45%] right-0 max-h-[100vh] h-[100vh] overflow-y-auto shadow-[rgba(0,0,15,0.1)_-10px_0px_20px_2px]">
          {listTab.map((item, index) => (
            <div
              key={index}
              className={`flex items-center justify-start cursor-pointer ${
                item.title === isActive
                  ? "bg-white font-extrabold"
                  : "bg-[#F2F7FF]"
              }`}
              onClick={() => {
                if (item.isActive !== "preview-profile") {
                  navigate(`/dashboard/edit-profile${item.link}`);
                } else {
                  navigate(`/dashboard/profile`);
                }
                setIsActive(item.title);
              }}
            >
              <div
                className={`h-14 w-[7px] ${
                  overview === item.isActive ? "bg-devRegGreen" : "bg-[#F2F7FF]"
                }`}
              ></div>
              <div className="text-sm text-neutral-700 pl-4">{item.title}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
  
}

export default SidebarMobile;
