import React, { useState, useEffect } from "react";
import Loading from "../../../../component/Loading.js";
import {
  getEducationAndCertificates,
  addEducationAndCertificate,
  updateEducationAndCertificate,
  deleteEducationAndCertificate,
} from "../../../../api/apiCall.js";
import moment from "moment";
import { FaCheckCircle } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

function EducationCert() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const [actionResponse, setActionResponse] = useState({ status: null, message: null });
  const [action, setAction] = useState('Add');
  const [isLoading, setIsLoading] = useState({ adding: false, fetching: false });
  const [isDeleting, setIsDeleting] = useState({ id: null, deleted: null });
  const [updatingId, setUpdatingId] = useState(null);

  const [educationAndCertificates, setEducationAndCertificates] = useState([]);
  const [schoolLogoPreview, setSchoolLogoPreview] = useState(null);
  const [schoolLogo, setSchoolLogo] = useState(null);
  const [nameOfSchool, setNameOfSchool] = useState(null);
  const [course, setCourse] = useState(null);
  const [dateStarted, setDateStarted] = useState(null);
  const [dateGraduated, setDateGraduated] = useState(null);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (actionResponse) {
      const timer = setTimeout(() => {
        setVisible(false);
        setActionResponse(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [actionResponse]);

  const handleClose = () => {
    setVisible(false);
    setActionResponse(false);
  };

  useEffect(() => {
    const inialFetch = async () => {
      setIsLoading((prev) => ({ ...prev, fetching: true }));
      await handleGetEducationAndCertificates();
      setIsLoading((prev) => ({ ...prev, fetching: false }));
    }

    inialFetch();
  }, [])

  const handleSchoolLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSchoolLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setSchoolLogo(file);
    }
  };

  const handleGetEducationAndCertificates = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
      }

      const response = await getEducationAndCertificates(data);
      if (response.status === 200) {
        setEducationAndCertificates(response.data);
      }
    }
    catch (error) {
      console.log("Error fetching education and certificates: ", error);
    }
  }

  const handleAddEducationAndCertificate = async () => {
    setIsLoading((prev) => ({ ...prev, adding: true }));
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          thumbnail: schoolLogo,
          name: nameOfSchool,
          degree_or_course: course,
          date_started: dateStarted,
          date_graduated: dateGraduated,
          type: "Education"
        }
      }

      const response = await addEducationAndCertificate(data);
      if (response.status === 201) {
        await handleGetEducationAndCertificates();
        setSchoolLogoPreview('');
        setSchoolLogo('');
        setNameOfSchool('');
        setCourse('');
        setDateStarted('');
        setDateGraduated('');
        setIsDeleting({ id: null, deleted: null });
        setActionResponse({ status: true, message: 'Education / Certificate Added' });
      }
    }
    catch (error) {
      console.log("Error adding education/certificate: ", error);
    }
    finally {
      setIsLoading((prev) => ({ ...prev, adding: false }));
    }
  }

  const handleDeleteEducationAndCertificate = async (educAndCertId) => {
    setIsDeleting({ id: educAndCertId, deleted: null });
    try {
      let data = {
        id: user.id,
        token: token,
        educAndCertId: educAndCertId
      }

      const response = await deleteEducationAndCertificate(data);
      if (response.status === 200) {
        setActionResponse({ status: null, message: null });
        setIsDeleting({ id: educAndCertId, deleted: true });
        handleGetEducationAndCertificates();
      }
    }
    catch (error) {
      console.log("Error fetching education and certificates: ", error);
    }
  }

  const formatDate = (inputDate) => {
    const date = moment(inputDate, 'YYYY-MM-DD');
    return date.format('YYYY');
  }

  const handleUpdateEducationAndCertificate = (data) => {
    window.scrollTo(0, 0)
    setActionResponse({ status: null, message: null });
    setAction('Update');
    setUpdatingId(data.id);
    setSchoolLogoPreview(data.thumbnail_path);
    setNameOfSchool(data.name);
    setCourse(data.degree_or_course);
    setDateStarted(data.date_started);
    setDateGraduated(data.date_graduated);
  }

  const handleCancelUpdateEducationAndCertificate = () => {
    setAction('Add');
    setUpdatingId('');
    setSchoolLogoPreview('');
    setNameOfSchool('');
    setCourse('');
    setDateStarted('');
    setDateGraduated('');
  }

  const handleSaveUpdateEducationAndCertificate = async () => {
    setIsLoading((prev) => ({ ...prev, adding: true }));
    try {
      let data = {
        id: user.id,
        token: token,
        educAndCertId: updatingId,
        payloads: {
          thumbnail: schoolLogo,
          name: nameOfSchool,
          degree_or_course: course,
          date_started: dateStarted,
          date_graduated: dateGraduated,
          type: "Education"
        }
      }

      const response = await updateEducationAndCertificate(data);
      if (response.status === 200) {
        await handleGetEducationAndCertificates();
        setAction('Add');
        setUpdatingId('');
        setSchoolLogoPreview('');
        setSchoolLogo('');
        setNameOfSchool('');
        setCourse('');
        setDateStarted('');
        setDateGraduated('');
        setIsDeleting({ id: null, deleted: null });
        setActionResponse({ status: true, message: 'Education / Certificate Updated' });
      }
    }
    catch (error) {
      console.log("Error updating education/certificate: ", error);
    }
    finally {
      setIsLoading((prev) => ({ ...prev, adding: false }));
    }
  }


  return (
    <>
      <div className="flex flex-col pt-6 max-md:pt-0 max-md:px-3 border-solid border-neutral-300 max-md:mt-8 max-md:max-w-full">
        {actionResponse.status && (
          <div className={`flex items-center self-center mb-6 max-md:mt-3 justify-between bg-white border border-gray-300 rounded-lg py-2 px-3 shadow-sm max-w-sm bg-green-100 text-green-600`}>
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
            <span className={`font-semibold mx-2 text-green-600`}>{actionResponse.message}</span>
            <div>
              <FaCheckCircle size={20} />
            </div>
          </div>
        )}
        <div className="flex justify-between gap-4 text-base tracking-tight max-md:flex-wrap">
          <div className="flex flex-col text-base tracking-tight max-md:flex-wrap">
            <div className="leading-[110%] text-base font-bold mb-2">
              {action} Education / Certificate
            </div>
            <p className="text-base tracking-tight leading-4 text-neutral-400 max-md:max-w-full">Showcase your learning! Add your education and certificates to highlight your academic achievements!</p>
          </div>
        </div>
        <div className="mt-6 max-md:max-w-full">
          <div className="flex flex-col gap-5 max-md:gap-0">
            <div className="flex relative flex-col w-full max-md:ml-0 max-md:w-full gap-3">
              <div className="text-base tracking-tight leading-4 font-normal">
                School / Institution Logo
              </div>
              <div className="flex flex-col w-[15%] max-[425px]:w-[25%] aspect-square h-auto grow overflow-hidden justify-center text-sm leading-4 text-center rounded-lg border border-solid border-neutral-300 text-stone-300 max-md:mt-0">
                {schoolLogoPreview ? (
                  <img
                    alt="img"
                    loading="lazy"
                    src={schoolLogoPreview}
                    className="self-center w-full"
                  />
                ) : (
                  <>
                    <div className="m-4 flex flex-col grow justify-center">
                      <img
                        alt="img"
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/176be2ea66493ab805bd3d9e5c7cd1f706a0de3766487bde8e82a9332aba88cd?"
                        className="self-center w-11 aspect-[0.95]"
                      />
                      <div>School / Institution Logo</div>
                    </div>
                  </>
                )}
              </div>
              <input
                type="file"
                value=""
                onChange={handleSchoolLogoUpload}
                className="opacity-0  absolute top-0 left-0 w-full h-full cursor-pointer"
              />
            </div>
            <div className="flex flex-col w-full max-md:ml-0 max-md:w-full">
              <div className="flex gap-3 flex-col w-full justify-center self-stretch my-auto text-base leading-5 placeholder-text-stone-300 max-md:mt-5 max-md:max-w-full">
                <div className="flex mt-2 max-[425px]:mt-0 flex-col w-full gap-3 max-md:flex-wrap max-md:max-w-full">
                  <div className="text-base tracking-tight leading-4 font-normal max-md:max-w-full">
                    School Name
                  </div>
                  <input
                    type="text"
                    placeholder="Name of School / Institution"
                    value={nameOfSchool}
                    onChange={(e) => setNameOfSchool(e.target.value)}
                    className="justify-center p-4 text-base rounded-lg border border-solid border-neutral-300 max-md:max-w-full"
                  />
                </div>
                <div className="flex mt-2 max-[425px]:mt-0 flex-col w-full gap-3 max-md:flex-wrap max-md:max-w-full">
                  <div className="text-base tracking-tight leading-4 font-normal max-md:max-w-full">
                    Degree / Course
                  </div>
                  <input
                    type="text"
                    placeholder="Degree / Course"
                    value={course}
                    onChange={(e) => setCourse(e.target.value)}
                    className="justify-center p-4 text-base rounded-lg border border-solid border-neutral-300 max-md:max-w-full"
                  />
                </div>
                <div className="flex gap-3 w-full items-center mt-2 max-[425px]:mt-4 max-md:flex-wrap max-md:max-w-full">
                  <div className="flex gap-3 w-full flex-col max-[425px]:w-full">
                    <label className="text-base tracking-tight leading-4 font-normal max-md:max-w-full"> Date Started</label>
                    <input
                      type="date"
                      value={dateStarted}
                      onChange={(e) => setDateStarted(e.target.value)}
                      placeholder="Date Started"
                      className="justify-center p-4 text-base rounded-lg border border-solid border-neutral-300 max-md:max-w-full"
                    />
                  </div>
                  <div className="flex gap-3 w-full flex-col max-[425px]:w-full">
                    <label className="text-base tracking-tight leading-4 font-normal max-md:max-w-full"> Date Graduated</label>
                    <input
                      type="date"
                      value={dateGraduated}
                      onChange={(e) => setDateGraduated(e.target.value)}
                      placeholder="Date Graduated"
                      className="justify-center p-4 text-base rounded-lg border border-solid border-neutral-300 max-md:max-w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full justify-end flex py-2 mt-10 max-w-full text-base font-semibold text-white w-[309px] max-md:pl-5 max-[425px]:w-full max-[425px]:px-0">
          <button
            className="justify-center whitespace-nowrap px-6 py-3 bg-devRegDarkBlue rounded-full max-md:px-5 w-[230px] h-14 max-md:ml-2 max-[425px]:w-full max-[425px]:ml-0"
            onClick={() => { action === 'Add' ? handleAddEducationAndCertificate() : handleSaveUpdateEducationAndCertificate() }}
            disabled={isLoading.adding}
          >
            {isLoading.adding ? (
              <svg
                className="animate-spin h-5 w-5 text-white mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              `${action} Education`
            )}
          </button>
        </div>

        <hr className="my-7" />

        <div className="flex flex-col mt-4 mb-5 text-base leading-6 bg-white rounded-xl text-neutral-600 max-md:max-w-full">
          <div className="text-3xl font-semibold text-neutral-800 max-md:max-w-full">
            Educational & Certification
          </div>
        </div>

        {!isLoading.fetching ? (
          educationAndCertificates.length > 0 ? (
            educationAndCertificates.map((educAndCert, index) => (
              <div className="flex gap-4 mt-6 max-md:flex-wrap border-b pb-6 border-gray-200" key={index}>
                <img
                  alt="img"
                  loading="lazy"
                  srcSet={educAndCert.thumbnail_path}
                  className="shrink-0 self-start aspect-square w-[77px]"
                />
                <div className="flex justify-between items-center flex-1 max-md:max-w-full max-[425px]:flex-col max-[425px]:items-start">
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="font-bold max-md:max-w-full text-devRegDarkBlue">
                      {educAndCert.name}
                    </div>
                    <div className="mt-1 max-md:max-w-full">
                      {educAndCert.degree_or_course}
                    </div>
                    <div className="mt-1 max-md:max-w-full">{formatDate(educAndCert.date_started)} - {formatDate(educAndCert.date_graduated)}</div>
                  </div>
                  <div className="flex gap-2 text-lg font-medium text-blue-900 max-[425px]:text-md max-[425px]:mt-1">
                    <div>
                      <button
                        onClick={() => { updatingId === educAndCert.id ? handleCancelUpdateEducationAndCertificate() : handleUpdateEducationAndCertificate(educAndCert) }}
                      >{updatingId === educAndCert.id ? 'Cancel' : 'Edit'}</button>
                    </div>
                    <div>|</div>
                    <div>
                      {isDeleting.id === educAndCert.id && isDeleting.deleted === null && (
                        <div className="w-[25px] h-[25px] border-2 border-t-transparent border-[#1c3775] rounded-full animate-spin"></div>
                      )}
                      {isDeleting.id === educAndCert.id && isDeleting.deleted === true && (
                        <svg width="25" height="25" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.9484 20.8869C9.46389 22.124 10.6735 23.911 11.4639 24.6498C12.8556 26.0072 15.9278 29.0106 17.0824 30.1653C18.2371 31.3199 19.6598 30.6464 20.2268 30.1653L33.1134 17.2787C33.5945 16.7976 34.268 15.4746 33.1134 14.0313C31.9587 12.588 30.4329 13.4299 29.8144 14.0313L18.5257 25.3199L15.0721 21.8663C14.0927 20.8869 12.8041 19.3405 10.9484 20.8869Z" fill="#00A018" />
                          <circle cx="22" cy="22" r="20" stroke="#00A018" stroke-width="4" />
                        </svg>
                      )}
                      {isDeleting.id !== educAndCert.id && (
                        <button className="text-red-500" onClick={() => handleDeleteEducationAndCertificate(educAndCert.id)}>Delete</button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-neutral-600 w-full">
              No Educations or Certificates added yet.
            </div>
          )
        ) : (
          <>
            <div className="w-full flex justify-center min-h-[200px]">
              <Loading />
            </div>
          </>
        )}
      </div>

      {educationAndCertificates?.length > 5 && (
        <div className="text-center mt-2 border-t-2">See more</div>
      )}

    </>
  );
}

export default EducationCert;
