import React, { useState, useEffect } from "react";
import { contactAdmin } from "../../../api/apiCall.js";
import { FaCheckCircle } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

function Help() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const [typeOfMessage, setTypeOfMessage] = useState(null);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState({ success: null, message: null });
  const [errors, setErrors] = useState({ messageType: null, subject: null, message: null });
  const [sending, setSending] = useState(false);
  const [visible, setVisible] = useState(true);

  const validate = () => {
    setErrors({ messageType: null, subject: null, message: null });
    let isValid = true;

    if (!typeOfMessage) {
      setErrors(prev => ({ ...prev, messageType: "Type of message is required" }));
      isValid = false;
    }
    if (typeOfMessage === "Complaint" && !subject) {
      setErrors(prev => ({ ...prev, subject: "Subject is required" }));
      isValid = false;
    }
    if (!message) {
      setErrors(prev => ({ ...prev, message: "Message is required" }));
      isValid = false;
    }

    return isValid;
  };

  const handleSend = async () => {
    setResponse({ success: null, message: null });
    if (!validate()) return;

    setSending(true);
    try {
      const data = {
        id: user.id,
        token: token,
        payloads: { message, subject },
        type: {
          Compliment: "compliments",
          Feedback: "feedbacks",
          Complaint: "complaints"
        }[typeOfMessage] || ""
      };
      const response = await contactAdmin(data);
      if (response.status === 201) {
        setResponse({ success: true, message: response.data.message });
      }
    } catch (error) {
      console.log("Error sending feedback: ", error);
      setResponse({ success: false, message: error.response?.data?.message });
    } finally {
      setSending(false);
    }
  };

  useEffect(() => {
    if (response.success !== null) {
      const timer = setTimeout(() => {
        setVisible(false);
        setResponse({ success: null, message: null });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [response]);

  const handleClose = () => {
    setVisible(false);
    setResponse({ success: null, message: null });
  };

  return (
    <div className="min-h-[87vh] pt-6 max-md:max-w-full px-5 max-md:px-3 max-md:pt-0">
      <div className="max-w-[700px] m-auto">
        <div className="flex justify-center items-center">
          {visible && response.message && (
            <div className={`flex items-center self-center mb-6 max-md:mt-3 justify-between bg-white border border-gray-300 rounded-lg py-2 px-3 shadow-sm max-w-sm ${response.success ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'}`}>
              <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
              <span className={`font-semibold mx-2 ${response.success ? 'text-green-600' : 'text-red-600'}`}>{response.message}</span>
              <div>
                {response.success ? <FaCheckCircle size={20} /> : <FaXmark size={20} />}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col border border-gray-300 py-6 rounded-lg items-start text-base leading-4 text-neutral-600 max-md:mt-8 max-md:max-w-full">
          <div className="self-start text-lg font-bold tracking-tight leading-5 text-blue-900 max-md:max-w-full px-8 max-md:px-4">
            Contact Admin
          </div>
          <hr className="border-b border-gray-200 w-full my-6"/>
          <div className="tracking-tight self-start px-8 max-md:px-4">
            Type of Message
          </div>
          <div className="flex text-base leading-4 px-8 max-md:px-4 text-neutral-600 max-md:px-5 max-md:max-w-full">
            <div className="flex gap-5 justify-start mt-4 max-w-full whitespace-nowrap leading-[121%] max-md:flex-col">
              <label className="flex gap-2 py-px items-center cursor-pointer">
                <input
                  type="radio"
                  value="Compliment"
                  checked={typeOfMessage === "Compliment"}
                  onChange={() => setTypeOfMessage("Compliment")}
                  className="peer shrink-0 w-4 h-4 appearance-none rounded-full border-2 border-gray-300 cursor-pointer checked:bg-teal-400"
                />
                <span>Compliment</span>
              </label>
              <label className="flex gap-2 py-0.5 items-center cursor-pointer">
                <input
                  type="radio"
                  value="Feedback"
                  checked={typeOfMessage === "Feedback"}
                  onChange={() => setTypeOfMessage("Feedback")}
                  className="peer shrink-0 w-4 h-4 appearance-none rounded-full border-2 border-gray-300 cursor-pointer checked:bg-teal-400"
                />
                <span className="my-auto">Feedback</span>
              </label>
              <label className="flex gap-2 py-px items-center cursor-pointer">
                <input
                  type="radio"
                  value="Complaint"
                  checked={typeOfMessage === "Complaint"}
                  onChange={() => setTypeOfMessage("Complaint")}
                  className="peer shrink-0 w-4 h-4 appearance-none rounded-full border-2 border-gray-300 cursor-pointer checked:bg-teal-400"
                />
                <span>Complaint</span>
              </label>
            </div>
          </div>

          <div className="px-8 w-full max-md:px-4">
            {errors.messageType && (
              <small className="self-start text-red-500 text-xs flex gap-1 mt-1">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z" stroke="#F94B4B" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                {errors.messageType}
              </small>
            )}

            {typeOfMessage === 'Complaint' && (
              <>
                <div className="mt-6 text-base self-start tracking-tight">
                  Subject
                </div>
                <input
                  type="text"
                  className="px-4 py-2 shrink-0 mt-2 max-w-full rounded-lg border border-solid border-neutral-300 w-full"
                  placeholder="Serial Number"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
                {errors.subject && (
                  <small className="self-start text-red-500 text-xs flex gap-1 mt-1">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z" stroke="#F94B4B" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {errors.subject}
                  </small>
                )}
              </>
            )}

            <div className="mt-6 text-base self-start tracking-tight">
              Message
            </div>
            <textarea
              className="px-4 py-2 shrink-0 mt-2 max-w-full rounded-lg border border-solid border-neutral-300 h-[180px] w-full"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {errors.message && (
              <small className="self-start text-red-500 text-xs flex gap-1 mt-1">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z" stroke="#F94B4B" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                {errors.message}
              </small>
            )}
            <div className="flex w-full justify-end">
              <div className="flex gap-4 mt-4 max-w-full font-semibold text-white w-[407px] max-md:flex-col">
                <div className="cursor-pointer text-center justify-center px-6 py-4 whitespace-nowrap bg-[#e0e7ff] text-blue-900 rounded-full max-md:px-5">
                  Cancel
                </div>
                <button
                  className={`flex-1 text-center justify-center items-center px-6 py-4 bg-blue-900 rounded-full max-md:px-5 ${sending ? 'opacity-[50%] cursor-not-allowed' : 'cursor-pointer'}`}
                  onClick={handleSend}
                  disabled={sending}
                >
                  {sending ? "Sending..." : "Send to Admin"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
