import React from "react";
import Accordion from "./AccordionFAQ";

function Faq() {
  return (
    <div className="bg-[#F9FAFB] pt-16 pb-28">
      <div className="max-w-main m-auto px-10 max-md:px-3">
        <div className=" font-bold max-md:text-sm20 text-[30px] text-devRegDarkBlue mb-5">
            Frequently Asked Questions
        </div>
        <Accordion />
        </div>
    </div>
  );
}

export default Faq;