import React, { useState, useRef, useEffect } from "react";
import { GoChevronUp, GoChevronDown } from "react-icons/go";
import { items } from "./faqData/basicFaqData";

function AccordionFAQ() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    if (contentRef.current) {
      setHeight(activeIndex !== null ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [activeIndex]);

  return (
    <div className="accordion">
      <style>
        {`
          .accordion-content {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.9s ease;
          }

          .accordion-content.expanded {
            max-height: var(--content-height);
          }

          .content-inner {
            padding: 0.5rem 0;
          }
        `}
      </style>
      {items.map((item, index) => (
        <div key={index} className="accordion-item mt-2 cursor-pointer border border-[#E5E7EB] bg-white px-4 py-3 rounded-lg">
          <div
            className={`accordion-title ${index === activeIndex ? "active" : ""} flex justify-between items-center`}
            onClick={() => onTitleClick(index)}
          >
            <div className={`text-[#170F49] font-semibold text-xl max-sm:text-base ${index === activeIndex ? "text-devRegBlue" : ""}`}>
              {item.title}
            </div>
            <div className={`accordion-arrow ${index === activeIndex ? "text-devRegBlue" : ""} text-devRegDarkBlue`}>
              {index === activeIndex ? <GoChevronUp size={30} /> : <GoChevronDown size={30} />}
            </div>
          </div>
          <div
            className={`accordion-content ${index === activeIndex ? "expanded" : ""} text-neutral-500 max-sm:pr-8`}
            ref={index === activeIndex ? contentRef : null}
            style={{ '--content-height': height }}
          >
            <div className="content-inner">{item.content}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AccordionFAQ;
