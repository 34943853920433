import React, { useState } from "react";
import Sidebar from "./components/Sidebar";
import ContentArea from "./components/ContentArea";

function Forum() {
  const [selectedCategory, setSelectedCategory] = useState("");

  const parentTunnel = (objectData) => {
    switch(objectData.type){
      case "category":
        setSelectedCategory(objectData.data.value);
        break;

      default:
        break;
    }
  }

  return (
    <div className="flex flex-wrap gap-6 pt-6 bg-white max-md:px-3">
      <Sidebar parentTunnel={parentTunnel}/>
      <ContentArea selectedCategory={selectedCategory}/>
    </div>
  );
}

export default Forum;
