import React, { useEffect, useState, useRef } from "react";
import {
  MessageDataInbox,
  AdminDataInbox,
  TheRegisterDataInbox,
} from "../data/MessageDataInbox";
import { FaRegTrashCan, FaRegEnvelope, FaRegEnvelopeOpen } from "react-icons/fa6";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

// import SidebarDesktop from "../../components/Dashboard/SidebarDesktop";
import { useLocation, useNavigate } from "react-router-dom";
import BannerProfile from "../components/BannerProfile";
import SidebarDashboard from "../components/SidebarDashboard";
import {
  getTheRegisterMessages,
  replyTheRegisterMessage,
  deleteTheRegisterMessage,
  bulkDeleteTheRegisterMessage,
  markAsReadUnreadTheRegisterMessage,
  getDevregMessages,
  replyDevregMessage,
  deleteDevregMessage,
  bulkDeleteDevregMessage,
  markAsReadUnreadDevregMessage,
  getAdminMessages,
  replyAdminMessage,
  deleteAdminMessage,
  bulkDeleteAdminMessage,
  markAsReadUnreadAdminMessage
} from "../../../api/apiCall.js";
import { DateTime } from 'luxon';
import Loading from "../../../component/Loading.js";
import OpenMessageDesktop from "./OpenMessageDesktop.js";
import OpenMessageMobile from "./OpenMessageMobile.js";

function MessagesMain() {
  const [toggleTab, setToggleTab] = React.useState(1);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');
  const messagesCount = JSON.parse(localStorage.getItem('messages_count'));
  const [isfetchingTheRegisterMessages, setIsfetchingTheRegisterMessages] = useState(false);
  const [isfetchingDevregMessages, setIsfetchingDevregMessages] = useState(false);
  const [isfetchingAdminMessages, setIsfetchingAdminMessages] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterOption, setFilterOption] = useState('All');
  const [selectedIds, setSelectedIds] = useState([]);


  // The Register
  const [theRegisterMessages, setTheRegisterMessages] = useState({ data: [] });
  // Devreg
  const [devregMessages, setDevregMessages] = useState({ data: [] });
  // Admin
  const [adminMessages, setAdminMessages] = useState({ data: [] });

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const handleSelectAll = (e) => {
    if(e.target.checked){
      switch(toggleTab){
        case 1:
          setSelectedIds([]);
          setSelectedIds((prevSelectedIds) => [
            ...prevSelectedIds, 
            ...theRegisterMessages.data.map(item => item.id)
          ]);
          break
        case 2:
          setSelectedIds([]);
          setSelectedIds((prevSelectedIds) => [
            ...prevSelectedIds, 
            ...devregMessages.data.map(item => item.id)
          ]);
          break;
        case 3:
          setSelectedIds([]);
          setSelectedIds((prevSelectedIds) => [
            ...prevSelectedIds, 
            ...adminMessages.data.map(item => item.id)
          ]);
          break;
  
        default:
          break
      }
    }
    else{
      setSelectedIds([]);
    }
  }

  const handleFilterChange = (option) => {
    setFilterOption(option);
    switch(option){
      case 'All':
        switch(toggleTab){
          case 1:
            setSelectedIds([]);
            setSelectedIds((prevSelectedIds) => [
              ...prevSelectedIds, 
              ...theRegisterMessages.data.map(item => item.id)
            ]);
            break
          case 2:
            setSelectedIds([]);
            setSelectedIds((prevSelectedIds) => [
              ...prevSelectedIds, 
              ...devregMessages.data.map(item => item.id)
            ]);
            break;
          case 3:
            setSelectedIds([]);
            setSelectedIds((prevSelectedIds) => [
              ...prevSelectedIds, 
              ...adminMessages.data.map(item => item.id)
            ]);
            break;
    
          default:
            break
        }
        break;
      case 'None':
        setSelectedIds([]);
        break;
      case 'Unread':
        switch(toggleTab){
          case 1:
            setSelectedIds([]);
            setSelectedIds((prevSelectedIds) => [
              ...prevSelectedIds,
              ...theRegisterMessages.data
                .filter((item) => item.is_seen === 0)
                .map((item) => item.id)
            ]);
            break
          case 2:
            setSelectedIds([]);
            setSelectedIds((prevSelectedIds) => [
              ...prevSelectedIds,
              ...devregMessages.data
                .filter((item) => item.is_seen === 0)
                .map((item) => item.id)
            ]);
            break;
          case 3:
            setSelectedIds([]);
            setSelectedIds((prevSelectedIds) => [
              ...prevSelectedIds,
              ...adminMessages.data
                .filter((item) => item.is_seen === 0)
                .map((item) => item.id)
            ]);
            break;
    
          default:
            break
        }
        break;
      case 'Read':
        switch(toggleTab){
          case 1:
            setSelectedIds([]);
            setSelectedIds((prevSelectedIds) => [
              ...prevSelectedIds,
              ...theRegisterMessages.data
                .filter((item) => item.is_seen === 1)
                .map((item) => item.id)
            ]);
            break
          case 2:
            setSelectedIds([]);
            setSelectedIds((prevSelectedIds) => [
              ...prevSelectedIds,
              ...devregMessages.data
                .filter((item) => item.is_seen === 1)
                .map((item) => item.id)
            ]);
            break;
          case 3:
            setSelectedIds([]);
            setSelectedIds((prevSelectedIds) => [
              ...prevSelectedIds,
              ...adminMessages.data
                .filter((item) => item.is_seen === 1)
                .map((item) => item.id)
            ]);
            break;
    
          default:
            break
        }
        break;
      default:
        break
    }
    
    console.log(selectedIds)
    setDropdownOpen(false);
  };


  const [replyInput, setReplyInput] = useState('');

  const [showMessage, setShowMessage] = useState(false);
  const [showReplyPanel, setShowReplyPanel] = useState(false);
  const [messageContent, setMessageContent] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setIsfetchingTheRegisterMessages(true);
    setIsfetchingDevregMessages(true);
    setIsfetchingAdminMessages(true);

    handleGetTheRegisterMessages();
    handleGetDevregMessages();
    handleGetAdminMessages();
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabParam = urlParams.get("tab");
    if (tabParam === "admin") {
      setToggleTab(3);
      setShowMessage(false);
      setMessageContent(null);
    }
    if (tabParam === "devreg") {
      setToggleTab(2);
      setShowMessage(false);
      setMessageContent(null);
    }
    if (tabParam === "theRegister") {
      setToggleTab(1);
      setShowMessage(false);
      setMessageContent(null);
    }

  }, [location]);

  const handleOpenMessage = (data) => {
    setMessageContent(data);
  };


  // ===================== The Register ======================
  const handleGetTheRegisterMessages = async (page) => {
    try {
      let data = {
        id: user.id,
        token: token,
        page: page ?? 1,
      }
      const response = await getTheRegisterMessages(data);
      console.log("The Register messages API response:", response);
      setTheRegisterMessages(response.data);
      messagesCount.theRegister = response.data.total;
      localStorage.setItem("messages_count", JSON.stringify(messagesCount));
    }
    catch (error) {
      console.error("Error fetching Register messages:", error);
    }
    finally {
      setIsfetchingTheRegisterMessages(false);
    }
  }

  const handlePrevPage = async (pageNumber) => {
    setSelectedIds([]);
    setIsfetchingTheRegisterMessages(true);
    await handleGetTheRegisterMessages(pageNumber)
  };

  const handleNextPage = async (pageNumber) => {
    setSelectedIds([]);
    setIsfetchingTheRegisterMessages(true);
    await handleGetTheRegisterMessages(pageNumber)
  };

  const handleReplyTheRegisterMessage = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
        messageId: messageContent.messageId,
        payloads: {
          subject: 'Reply',
          message: replyInput,
          receiver_email: messageContent.from
        }
      }
      const response = await replyTheRegisterMessage(data);
      console.log("API response:", response);
    }
    catch (error) {
      console.error("Error sending reply:", error.response.data);
    }
  }

  const handleDeleteTheRegisterMessage = async (id) => {
    try {
      let data = {
        id: user.id,
        token: token,
        messageType: "received",
        messageId: id || messageContent.messageId
      }
      const response = await deleteTheRegisterMessage(data);
      if (response.status === 200) {
        messagesCount.theRegister -= 1;
        localStorage.setItem("messages_count", JSON.stringify(messagesCount));
        setTheRegisterMessages((prevTheRegisterMessages) => ({
          ...prevTheRegisterMessages,
          data: prevTheRegisterMessages.data.filter((item) => item.id !== data.messageId),
        }));
        setShowMessage(false);
        setMessageContent({
          messageType: "",
          messageId: "",
          name: "",
          subject: "",
          msg: "",
          time: "",
          from: "",
        });
      }
      console.log("API response:", response);
    }
    catch (error) {
      console.error("Error deleting a message:", error);
    }
    finally {

    }
  }

  const handleBulkDeleteTheRegisterMessage = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
        messageType: 'received',
        payloads: {
          ids: selectedIds
        }
      }

      console.log(data)
      const response = await bulkDeleteTheRegisterMessage(data);
      if (response.status === 200) {
        setTheRegisterMessages((prevTheRegisterMessages) => ({
          ...prevTheRegisterMessages,
          data: prevTheRegisterMessages.data.filter((item) => !data.payloads.ids.includes(item.id)),
        }));
        setShowMessage(false);
        setMessageContent({
          messageType: "",
          messageId: "",
          name: "",
          subject: "",
          msg: "",
          time: "",
          from: "",
        });
      }
      console.log("API response:", response);
    }
    catch (error) {
      console.error("Error deleting a message:", error);
    }
    finally {

    }
  }

  const handleMarkAsTheRegisterMessage = async (mark, id) => {
    try {
      let data = {
        id: user.id,
        token: token,
        messageType: "received",
        payloads: {
          mark_as_read: mark
        }
      }
      if(id){
        data.payloads.ids = [id];
      }
      else{
        data.payloads.ids = selectedIds;
      }
      const response = await markAsReadUnreadTheRegisterMessage(data);
      if (response.status === 200) {
        const idSet = new Set(data.payloads.ids.map(Number));
        setTheRegisterMessages((prevTheRegisterMessages) => ({
          ...prevTheRegisterMessages,
          data: prevTheRegisterMessages.data
            .map((item) => {
              if (idSet.has(item.id)) {
                return { ...item, is_seen: mark };
              }
              return item;
            })
        }));
        setShowMessage(false);
        setMessageContent({
          messageType: "",
          messageId: "",
          name: "",
          subject: "",
          msg: "",
          time: "",
          from: "",
        });
      }
      console.log("API response:", response);
    }
    catch (error) {
      console.error("Error marking a message:", error);
    }
    finally {

    }
  }

  // ====================== Devreg ===========================
  const handleGetDevregMessages = async (page) => {
    try {
      let data = {
        id: user.id,
        token: token,
        page: page ?? 1,
      }
      const response = await getDevregMessages(data);
      console.log("Devreg message API response:", response);
      setDevregMessages(response.data)
      messagesCount.devreg = response.data.total;
      localStorage.setItem("messages_count", JSON.stringify(messagesCount));
    }
    catch (error) {
      console.error("Error fetching Devreg messages:", error);
    }
    finally {
      setIsfetchingDevregMessages(false);
    }
  }

  const handlePrevPageDevreg = async (pageNumber) => {
    setSelectedIds([]);
    setIsfetchingDevregMessages(true);
    await handleGetDevregMessages(pageNumber)
  };

  const handleNextPageDevreg = async (pageNumber) => {
    setSelectedIds([]);
    setIsfetchingDevregMessages(true);
    await handleGetDevregMessages(pageNumber)
  };

  const handleReplyDevregMessage = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
        messageId: messageContent.messageId,
        payloads: {
          subject: 'Reply',
          message: replyInput,
          receiver_email: messageContent.from
        }
      }
      const response = await replyDevregMessage(data);
      console.log("API response:", response);
    }
    catch (error) {
      console.error("Error sending reply:", error.response.data);
    }
  }

  const handleDeleteDevregMessage = async (id) => {
    try {
      let data = {
        id: user.id,
        token: token,
        messageType: "received",
        messageId: id || messageContent.messageId
      }
      const response = await deleteDevregMessage(data);
      if (response.status === 200) {
        messagesCount.devreg -= 1;
        localStorage.setItem("messages_count", JSON.stringify(messagesCount));
        setDevregMessages((prevDevregMessages) => ({
          ...prevDevregMessages,
          data: prevDevregMessages.data.filter((item) => item.id !== data.messageId),
        }));
        setShowMessage(false);
        setMessageContent({
          messageType: "",
          messageId: "",
          name: "",
          subject: "",
          msg: "",
          time: "",
          from: "",
        });
      }
      console.log("API response:", response);
    }
    catch (error) {
      console.error("Error deleting a message:", error);
    }
    finally {

    }
  }

  const handleBulkDeleteDevregMessage = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
        messageType: 'received',
        payloads: {
          ids: selectedIds
        }
      }
      const response = await bulkDeleteDevregMessage(data);
      if (response.status === 200) {
        console.log(data.payloads.ids)
        setDevregMessages((prevDevregMessages) => ({
          ...prevDevregMessages,
          data: prevDevregMessages.data.filter((item) => !data.payloads.ids.includes(item.id)),
        }));
        setShowMessage(false);
        setMessageContent({
          messageType: "",
          messageId: "",
          name: "",
          subject: "",
          msg: "",
          time: "",
          from: "",
        });
      }
      console.log("API response:", response);
    }
    catch (error) {
      console.error("Error deleting a message:", error);
    }
    finally {

    }
  }

  const handleMarkAsDevregMessage = async (mark, id) => {
    try {
      let data = {
        id: user.id,
        token: token,
        messageType: "received",
        payloads: {
          mark_as_read: mark
        }
      }
      if(id){
        data.payloads.ids = [id];
      }
      else{
        data.payloads.ids = selectedIds;
      }
      const response = await markAsReadUnreadDevregMessage(data);
      if (response.status === 200) {
        const idSet = new Set(data.payloads.ids.map(Number));
        setDevregMessages((prevDevregMessages) => ({
          ...prevDevregMessages,
          data: prevDevregMessages.data
            .map((item) => {
              if (idSet.has(item.id)) {
                return { ...item, is_seen: mark };
              }
              return item;
            })
        }));
        setShowMessage(false);
        setMessageContent({
          messageType: "",
          messageId: "",
          name: "",
          subject: "",
          msg: "",
          time: "",
          from: "",
        });
      }
      console.log("API response:", response);
    }
    catch (error) {
      console.error("Error marking a message:", error);
    }
    finally {

    }
  }

  // ====================== Admin ===========================
  const handleGetAdminMessages = async (page) => {
    try {
      let data = {
        id: user.id,
        token: token,
        page: page ?? 1,
      }
      const response = await getAdminMessages(data);
      console.log("Admin message API response:", response);
      setAdminMessages(response.data)
      messagesCount.admin = response.data.total;
      localStorage.setItem("messages_count", JSON.stringify(messagesCount));
    }
    catch (error) {
      console.error("Error fetching Admin messages:", error);
    }
    finally {
      setIsfetchingAdminMessages(false);
    }
  }

  const handlePrevPageAdmin = async (pageNumber) => {
    setSelectedIds([]);
    setIsfetchingAdminMessages(true);
    await handleGetAdminMessages(pageNumber)
  };

  const handleNextPageAdmin = async (pageNumber) => {
    setSelectedIds([]);
    setIsfetchingAdminMessages(true);
    await handleGetAdminMessages(pageNumber)
  };

  const handleReplyAdminMessage = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
        messageId: messageContent.messageId,
        payloads: {
          subject: 'Reply',
          message: replyInput,
          receiver_email: messageContent.from
        }
      }
      const response = await replyAdminMessage(data);
      console.log("API response:", response);
    }
    catch (error) {
      console.error("Error sending reply:", error.response.data);
    }
  }

  const handleDeleteAdminMessage = async (id) => {
    try {
      let data = {
        id: user.id,
        token: token,
        messageType: "received",
        messageId: id || messageContent.messageId
      }
      const response = await deleteAdminMessage(data);
      if (response.status === 200) {
        messagesCount.admin -= 1;
        localStorage.setItem("messages_count", JSON.stringify(messagesCount));
        setAdminMessages((prevAdminMessages) => ({
          ...prevAdminMessages,
          data: prevAdminMessages.data.filter((item) => item.id !== data.messageId),
        }));
        setShowMessage(false);
        setMessageContent({
          messageType: "",
          messageId: "",
          name: "",
          subject: "",
          msg: "",
          time: "",
          from: "",
        });
      }
      console.log("API response:", response);
    }
    catch (error) {
      console.error("Error deleting a message:", error);
    }
    finally {

    }
  }

  const handleBulkDeleteAdminMessage = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
        messageType: 'received',
        payloads: {
          ids: selectedIds
        }
      }
      const response = await bulkDeleteAdminMessage(data);
      if (response.status === 200) {
        setAdminMessages((prevAdminMessages) => ({
          ...prevAdminMessages,
          data: prevAdminMessages.data.filter((item) => !data.payloads.ids.includes(item.id)),
        }));
        setShowMessage(false);
        setMessageContent({
          messageType: "",
          messageId: "",
          name: "",
          subject: "",
          msg: "",
          time: "",
          from: "",
        });
      }
      console.log("API response:", response);
    }
    catch (error) {
      console.error("Error deleting a message:", error);
    }
    finally {

    }
  }

  const handleMarkAsAdminMessage = async (mark, id) => {
    try {
      let data = {
        id: user.id,
        token: token,
        messageType: "received",
        payloads: {
          mark_as_read: mark
        }
      }
      if(id){
        data.payloads.ids = [id];
      }
      else{
        data.payloads.ids = selectedIds;
      }
      const response = await markAsReadUnreadAdminMessage(data);
      if (response.status === 200) {
        const idSet = new Set(data.payloads.ids.map(Number));
        setAdminMessages((prevAdminMessages) => ({
          ...prevAdminMessages,
          data: prevAdminMessages.data
            .map((item) => {
              if (idSet.has(item.id)) {
                return { ...item, is_seen: mark };
              }
              return item;
            })
        }));
        setShowMessage(false);
        setMessageContent({
          messageType: "",
          messageId: "",
          name: "",
          subject: "",
          msg: "",
          time: "",
          from: "",
        });
      }
      console.log("API response:", response);
    }
    catch (error) {
      console.error("Error marking a message:", error);
    }
    finally {

    }
  }

  const refreshMessage = async (tab) => {
    switch (tab) {
      case "theRegister":
        setIsfetchingTheRegisterMessages(true);
        await handleGetTheRegisterMessages();
        break;
      case "devreg":
        setIsfetchingDevregMessages(true);
        handleGetDevregMessages();
        break;
      case "admin":
        setIsfetchingAdminMessages(true);
        handleGetAdminMessages();
        break;
      default:
        break;
    }
  }

  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id]);
    }
    else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((selectedId) => selectedId !== id)
      );
    }
  };

  const formatTimestamp = (timestamp) => {
    const dt = DateTime.fromISO(timestamp).toLocal();
    return dt.toLocaleString({ hour: 'numeric', minute: '2-digit', hour12: true });
  };

  const emit = (callback) => {
    if (callback.type === "setShowMessage") {
      setShowMessage(callback.data);
    }
  }

  return (
    <>
      <div className="max-[1024px]:w-[655px] min-h-[87vh] max-[767px]:hidden pt-6 max-md:pt-0">
        <div className="m-auto">
          <BannerProfile />
          {!showMessage && (
            <>
              <div>
                <div className="title text-[30px] mt-4 font-bold max-sm:text-[23px]">
                  Message inbox
                </div>

                <div className="flex justify-between">
                  <div className="nav-msg flex mt-2 text-xl max-sm:text-[17px] whitespace-nowrap max-sm:px-2 font-bold cursor-pointer text-[#888888]">
                    <div
                      className={`px-4 max-sm:pr-6 border-4 border-transparent py-3 ${toggleTab === 1 &&
                        "border-b-devRegDarkBlue text-devRegDarkBlue "
                        }`}
                      onClick={() => {
                        setToggleTab(1);
                        setSelectedIds([]);
                        refreshMessage("theRegister");
                        navigate("/dashboard/messages?tab=theRegister");
                      }}
                    >
                      The Register
                    </div>
                    <div
                      className={`px-4 max-sm:pr-6 border-4 border-transparent py-3 ${toggleTab === 2 &&
                        "border-b-devRegDarkBlue text-devRegDarkBlue  "
                        }`}
                      onClick={() => {
                        setToggleTab(2);
                        setSelectedIds([]);
                        refreshMessage("devreg");
                        navigate("/dashboard/messages?tab=devreg");
                      }}
                    >
                      Devreg
                    </div>
                    <div
                      className={`px-4 max-sm:pr-6 border-4 border-transparent py-3 ${toggleTab === 3 &&
                        "border-b-devRegDarkBlue text-devRegDarkBlue  "
                        }`}
                      onClick={() => {
                        setToggleTab(3);
                        setSelectedIds([]);
                        refreshMessage("admin");
                        navigate("/dashboard/messages?tab=admin");
                      }}
                    >
                      Admin
                    </div>
                  </div>
                </div>

                <div className="h-[2px] mt-[-2px] w-full bg-[#bab8b8] rounded-full "></div>


                <div className="mt-2 min-w-0">

                  {toggleTab === 1 && (
                    !isfetchingTheRegisterMessages ? (
                      <>
                        <div className="flex items-center gap-6 px-4 py-2 border-b border-gray-200">
                          <div className="relative flex items-center">
                            <input
                              type="checkbox"
                              className="mr-2"
                              checked={selectedIds.length === theRegisterMessages.data.length && theRegisterMessages.data.length > 0}
                              onChange={(e) => handleSelectAll(e)}
                            />
                            <button
                              onClick={toggleDropdown}
                              className="ml-2 text-gray-600 flex items-center"
                            >
                              <span className="text-sm">{filterOption}</span>
                              <svg
                                className="ml-1 w-4 h-4 inline-block"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            </button>
                            {dropdownOpen && (
                              <div className="absolute top-6 left-3 mt-1 w-40 bg-white border border-gray-300 rounded shadow-lg z-10">
                                <ul className="py-1">
                                  {['All', 'None', 'Unread', 'Read'].map(option => (
                                    <li
                                      key={option}
                                      className="px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                      onClick={() => handleFilterChange(option)}
                                    >
                                      {option}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                          {selectedIds.length > 0 && toggleTab === 1 && (
                            <div className="flex items-center gap-6">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMarkAsTheRegisterMessage(0)
                                }}
                                className="mr-3">
                                <FaRegEnvelope size="20px" className="fas fa-envelope text-gray-600 " />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMarkAsTheRegisterMessage(1)
                                }}
                                className="mr-3">
                                <FaRegEnvelopeOpen size="18px" className="fas fa-envelope text-gray-600" />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleBulkDeleteTheRegisterMessage()
                                }}
                                className="mr-3">
                                <FaRegTrashCan size="20px" className="fas fa-trash text-gray-600" />
                              </button>
                            </div>
                          )}
                          <div className="ml-auto flex items-center text-sm text-gray-600">
                            <span>{`Showing ${theRegisterMessages.from ?? 0} ${theRegisterMessages.to ? '- '+theRegisterMessages.to:''} of ${theRegisterMessages.total}`}</span>
                            <button className={`ml-2 cursor-pointer ${theRegisterMessages.prev_page_url === null ? 'text-gray-400':'text-gray-700'}`} onClick={() => handlePrevPage(theRegisterMessages.current_page-1)} disabled={theRegisterMessages.prev_page_url === null}>
                              <MdChevronLeft size={20} />
                            </button>
                            <span className="ml-2">{`Page ${theRegisterMessages.current_page} of ${Math.ceil(theRegisterMessages.total / theRegisterMessages.per_page)}`}</span>
                            <button className={`ml-2 cursor-pointer ${theRegisterMessages.next_page_url === null ? 'text-gray-400':'text-gray-700'}`} onClick={() => handleNextPage(theRegisterMessages.current_page+1)} disabled={theRegisterMessages.next_page_url === null}>
                              <MdChevronRight size={20} />
                            </button>
                          </div>
                        </div>

                        {theRegisterMessages.data.length === 0 ? (
                          <h1 className="text-center py-[100px]">No Messages</h1>
                        ) : (
                          theRegisterMessages.data.map((item, index) => (
                            <div
                              key={index}
                              className={`group relative flex items-center py-2 px-4 cursor-pointer 
                                ${selectedIds.includes(item.id) ? 'bg-blue-300 text-white' : 'hover:bg-gray-100'}
                                border-b border-gray-200`}
                              onClick={() => {
                                setShowMessage(true);
                                handleOpenMessage(item);
                              }}
                            >
                              <input
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => handleCheckboxChange(e, item.id)}
                                checked={selectedIds.includes(item.id)}
                                type="checkbox"
                                className="mr-3"
                              />
                              <div className="flex-grow gap-2 flex items-center min-w-0">
                                <div className="font-bold w-1/5 truncate">{item.sender.fullname}</div>
                                <div className="font-bold w-1/5 truncate">{item.subject}</div>
                                <div className={`w-2/5 text-gray-500 truncate ${item.is_seen === 0 ? 'font-bold' : ''}`}>
                                  {item.message_content.length > 70
                                    ? item.message_content.substring(0, 70) + "..."
                                    : item.message_content}
                                </div>
                                <div className="w-1/5 text-right font-bold relative">
                                  <div className="group-hover:hidden">{formatTimestamp(item.created_at)}</div>
                                  <div className="absolute inset-0 flex items-center justify-end gap-4 hidden group-hover:flex">
                                    {item.is_seen === 0 && (
                                      <button onClick={(e) => {
                                        e.stopPropagation();
                                        handleMarkAsTheRegisterMessage(1, item.id)
                                      }}>
                                        <FaRegEnvelopeOpen size="20px" className="text-gray-600 " />
                                      </button>
                                    )}
                                    {item.is_seen === 1 && (
                                      <button onClick={(e) => {
                                        e.stopPropagation();
                                        handleMarkAsTheRegisterMessage(0, item.id)
                                      }}>
                                        <FaRegEnvelope size="20px" className="text-gray-600 " />
                                      </button>
                                    )}
                                    <button onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteTheRegisterMessage(item.id)
                                    }}>
                                      <FaRegTrashCan size="20px" className="text-gray-600" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </>
                    ) : (
                      <div className="mt-[100px]">
                        <Loading />
                      </div>
                    )
                  )}

                  {toggleTab === 2 && (
                    !isfetchingDevregMessages ? (
                      <>
                        <div className="flex items-center gap-6 px-4 py-2 border-b border-gray-200">
                          <div className="relative flex items-center">
                            <input
                              type="checkbox"
                              className="mr-2"
                              checked={selectedIds.length === devregMessages.data.length && devregMessages.data.length > 0}
                              onChange={(e) => handleSelectAll(e)}
                            />
                            <button
                              onClick={toggleDropdown}
                              className="ml-2 text-gray-600 flex items-center"
                            >
                              <span className="text-sm">{filterOption}</span>
                              <svg
                                className="ml-1 w-4 h-4 inline-block"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            </button>
                            {dropdownOpen && (
                              <div className="absolute top-6 left-3 mt-1 w-40 bg-white border border-gray-300 rounded shadow-lg z-10">
                                <ul className="py-1">
                                  {['All', 'None', 'Unread', 'Read'].map(option => (
                                    <li
                                      key={option}
                                      className="px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                      onClick={() => handleFilterChange(option)}
                                    >
                                      {option}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                          {selectedIds.length > 0 && toggleTab === 2 && (
                            <div className="flex items-center gap-6">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMarkAsDevregMessage(0)
                                }}
                                className="mr-3">
                                <FaRegEnvelope size="20px" className="text-gray-600 " />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMarkAsDevregMessage(1)
                                }}
                                className="mr-3">
                                <FaRegEnvelopeOpen size="18px" className="ext-gray-600" />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleBulkDeleteDevregMessage(selectedIds)
                                }}
                                className="mr-3">
                                <FaRegTrashCan size="20px" className="text-gray-600" />
                              </button>
                            </div>
                          )}
                          <div className="ml-auto flex items-center text-sm text-gray-600">
                            <span>{`Showing ${devregMessages.from ?? 0} ${devregMessages.to ? '- '+devregMessages.to:''} of ${devregMessages.total}`}</span>
                            <button className={`ml-2 cursor-pointer ${devregMessages.prev_page_url === null ? 'text-gray-400':'text-gray-700'}`} onClick={() => handlePrevPageDevreg(devregMessages.current_page-1)} disabled={devregMessages.prev_page_url === null}>
                              <MdChevronLeft size={20} />
                            </button>
                            <span className="ml-2">{`Page ${devregMessages.current_page} of ${Math.ceil(devregMessages.total / devregMessages.per_page)}`}</span>
                            <button className={`ml-2 cursor-pointer ${devregMessages.next_page_url === null ? 'text-gray-400':'text-gray-700'}`} onClick={() => handleNextPageDevreg(devregMessages.current_page+1)} disabled={devregMessages.next_page_url === null}>
                              <MdChevronRight size={20} />
                            </button>
                          </div>
                        </div>
                        {devregMessages.data.length === 0 && (
                          <h1 className="text-center py-[100px]">No Messages</h1>
                        )}
                        {devregMessages.data.length > 0 && (
                          devregMessages.data.map((item, index) => (
                            <div
                              key={index}
                              className={`group relative flex items-center py-2 px-4 cursor-pointer 
                                ${selectedIds.includes(item.id) ? 'bg-blue-300 text-white' : 'hover:bg-gray-100'}
                                border-b border-gray-200`}
                              onClick={() => {
                                setShowMessage(true);
                                handleOpenMessage(item);
                              }}
                            >
                              <input
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => handleCheckboxChange(e, item.id)}
                                checked={selectedIds.includes(item.id)}
                                type="checkbox"
                                className="mr-3"
                                name="checkBox"
                                id="checkBox"
                              />
                              <div className="flex-grow gap-2 flex items-center min-w-0">
                                <div className="font-bold w-1/5 truncate">{item.sender.fullname}</div>
                                <div className="font-bold w-1/5 truncate">{item.subject}</div>
                                <div className={`w-2/5 text-gray-500 truncate ${item.is_seen === 0 ? 'font-bold' : ''}`}>
                                  {item.message_content.length > 70
                                    ? item.message_content.substring(0, 70) + "..."
                                    : item.message_content}
                                </div>
                                <div className="w-1/5 text-right font-bold relative">
                                  <div className="group-hover:hidden">{formatTimestamp(item.created_at)}</div>
                                  <div className="absolute inset-0 flex items-center justify-end gap-4 hidden group-hover:flex">
                                    {item.is_seen === 0 && (
                                      <button onClick={(e) => {
                                        e.stopPropagation();
                                        handleMarkAsDevregMessage(1, item.id)
                                      }}>
                                        <FaRegEnvelopeOpen size="20px" className="text-gray-600 " />
                                      </button>
                                    )}
                                    {item.is_seen === 1 && (
                                      <button onClick={(e) => {
                                        e.stopPropagation();
                                        handleMarkAsDevregMessage(0, item.id)
                                      }}>
                                        <FaRegEnvelope size="20px" className="text-gray-600 " />
                                      </button>
                                    )}
                                    <button onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteDevregMessage(item.id)
                                    }}>
                                      <FaRegTrashCan size="20px" className="text-gray-600" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )))}
                      </>
                    ) : (
                      <div className="mt-[100px]">
                        <Loading />
                      </div>
                    )
                  )}

                  {toggleTab === 3 && (
                    !isfetchingAdminMessages ? (
                      <>
                        <div className="flex items-center gap-6 px-4 py-2 border-b border-gray-200">
                          <div className="relative flex items-center">
                            <input
                              type="checkbox"
                              className="mr-2"
                              checked={selectedIds.length === adminMessages.data.length && adminMessages.data.length > 0}
                              onChange={(e) => handleSelectAll(e)}
                            />
                            <button
                              onClick={toggleDropdown}
                              className="ml-2 text-gray-600 flex items-center"
                            >
                              <span className="text-sm">{filterOption}</span>
                              <svg
                                className="ml-1 w-4 h-4 inline-block"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            </button>
                            {dropdownOpen && (
                              <div className="absolute top-6 left-3 mt-1 w-40 bg-white border border-gray-300 rounded shadow-lg z-10">
                                <ul className="py-1">
                                  {['All', 'None', 'Unread', 'Read'].map(option => (
                                    <li
                                      key={option}
                                      className="px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                      onClick={() => handleFilterChange(option)}
                                    >
                                      {option}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                          {selectedIds.length > 0 && toggleTab === 3 && (
                            <div className="flex items-center gap-6">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMarkAsAdminMessage(0)
                                }}
                                className="mr-3">
                                <FaRegEnvelope size="20px" className="text-gray-600" />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMarkAsAdminMessage(1)
                                }}
                                className="mr-3">
                                <FaRegEnvelopeOpen size="18px" className="text-gray-600" />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleBulkDeleteAdminMessage(selectedIds)
                                }}
                                className="mr-3">
                                <FaRegTrashCan size="20px" className="text-gray-600" />
                              </button>
                            </div>
                          )}
                          <div className="ml-auto flex items-center text-sm text-gray-600">
                            <span>{`Showing ${adminMessages.from ?? 0} ${adminMessages.to ? '- '+adminMessages.to:''} of ${adminMessages.total}`}</span>
                            <button className={`ml-2 cursor-pointer ${adminMessages.prev_page_url === null ? 'text-gray-400':'text-gray-700'}`} onClick={() => handlePrevPageAdmin(adminMessages.current_page-1)} disabled={adminMessages.prev_page_url === null}>
                              <MdChevronLeft size={20} />
                            </button>
                            <span className="ml-2">{`Page ${adminMessages.current_page} of ${Math.ceil(adminMessages.total / adminMessages.per_page)}`}</span>
                            <button className={`ml-2 cursor-pointer ${adminMessages.next_page_url === null ? 'text-gray-400':'text-gray-700'}`} onClick={() => handleNextPageAdmin(adminMessages.current_page+1)} disabled={adminMessages.next_page_url === null}>
                              <MdChevronRight size={20} />
                            </button>
                          </div>
                        </div>
                        {adminMessages.data.length === 0 && (
                          <h1 className="text-center py-[100px]">No Messages</h1>
                        )}
                        {adminMessages.data.length > 0 && (
                          adminMessages.data.map((item, index) => (
                            <div
                              key={index}
                              className={`group relative flex items-center py-2 px-4 cursor-pointer 
                                ${selectedIds.includes(item.id) ? 'bg-blue-300 text-white' : 'hover:bg-gray-100'}
                                border-b border-gray-200`}
                              onClick={() => {
                                setShowMessage(true);
                                handleOpenMessage(item);
                              }}
                            >
                              <input
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => handleCheckboxChange(e, item.id)}
                                checked={selectedIds.includes(item.id)}
                                type="checkbox"
                                className="mr-3"
                                name="checkBox"
                                id="checkBox"
                              />
                              <div className="flex-grow gap-2 flex items-center min-w-0">
                                <div className="font-bold w-1/5 truncate">{item.sender.fullname}</div>
                                <div className="font-bold w-1/5 truncate">{item.subject}</div>
                                <div className={`w-2/5 text-gray-500 truncate ${item.is_seen === 0 ? 'font-bold' : ''}`}>
                                  {item.message_content.length > 70
                                    ? item.message_content.substring(0, 70) + "..."
                                    : item.message_content}
                                </div>
                                <div className="w-1/5 text-right font-bold relative">
                                  <div className="group-hover:hidden">{formatTimestamp(item.created_at)}</div>
                                  <div className="absolute inset-0 flex items-center justify-end gap-4 hidden group-hover:flex">
                                    {item.is_seen === 0 && (
                                      <button onClick={(e) => {
                                        e.stopPropagation();
                                        handleMarkAsAdminMessage(1, item.id)
                                      }}>
                                        <FaRegEnvelopeOpen size="20px" className="text-gray-600" />
                                      </button>
                                    )}
                                    {item.is_seen === 1 && (
                                      <button onClick={(e) => {
                                        e.stopPropagation();
                                        handleMarkAsAdminMessage(0, item.id)
                                      }}>
                                        <FaRegEnvelope size="20px" className="text-gray-600" />
                                      </button>
                                    )}
                                    <button onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteAdminMessage(item.id)
                                    }}>
                                      <FaRegTrashCan size="20px" className="text-gray-600" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </>
                    ) : (
                      <div className="mt-[100px]">
                        <Loading />
                      </div>
                    )
                  )}

                </div>
              </div>
            </>
          )}

          {showMessage && (
            <>
              <OpenMessageDesktop data={messageContent} emit={emit} />
            </>
          )}
        </div>
      </div>

      <div className=" min-h-[87vh] min-[767px]:hidden">
        <div className="m-auto">
          {!showMessage && (
            <>
              <div className="relative mb-4">
                <input
                  type="search"
                  name="search"
                  id="search"
                  placeholder="Search"
                  className="py-3 w-full rounded pr-10"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="20"
                  height="20"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 4a7 7 0 11-7 7 7 7 0 017-7zM21 21l-4.35-4.35"
                  />
                </svg>
              </div>
              <div
                className={`pr-8 max-sm:pr-6 border-4 border-transparent py-1 ${toggleTab === 1 ? " text-[#1C3775] font-semibold" : "hidden"
                  }`}
                onClick={() => {
                  setToggleTab(1);
                  navigate("/dashboard/messages?tab=theRegister");
                }}
              >
                The Register
              </div>
              <div
                className={`pr-8 max-sm:pr-6 border-4 border-transparent py-1 ${toggleTab === 2 ? " text-[#1C3775] font-semibold" : "hidden"
                  }`}
                onClick={() => {
                  setToggleTab(2);
                  navigate("/dashboard/messages?tab=devreg");
                }}
              >
                Devreg
              </div>
              <div
                className={`pr-8 max-sm:pr-6 border-4 border-transparent py-1 ${toggleTab === 3 ? " text-[#1C3775] font-semibold" : "hidden"
                  }`}
                onClick={() => {
                  setToggleTab(3);
                  navigate("/dashboard/messages?tab=admin");
                }}
              >
                Admin
              </div>
              <div className="h-[1px] w-full bg-[#e2e2e2] rounded-full "></div>

              <div className="flex items-center px-4 space-x-5 my-4">
                <input
                  type="checkbox"
                  name="checkboxSelectAll"
                  id="checkboxSelectAll"
                  className="h-4 w-4"
                />
                <FaRegEnvelope size={20} />
                <FaRegEnvelopeOpen size={18} />
                <FaRegTrashCan size={20} />
              </div>

              <div className="h-[1px] w-full bg-[#e2e2e2] rounded-full "></div>

              <div className="mt-2">
                {toggleTab === 1 && !isfetchingTheRegisterMessages && (
                  <>
                    {theRegisterMessages.data.length === 0 ? (
                      <h1 className="text-center py-[100px]">No Messages</h1>
                    ) : (
                      <>
                        {theRegisterMessages.data.map((item, index) => (
                          <div
                            key={index}
                            className={`py-2 px-4 cursor-pointer 
              ${selectedIds.includes(item.id) ? 'bg-blue-300 text-white' : 'hover:bg-gray-100'}
              border-b border-gray-200`}
                            onClick={() => {
                              setShowMessage(true);
                              handleOpenMessage(item);
                            }}
                          >
                            <div className="flex justify-between items-center">
                              <div className="flex">
                                <input
                                  onChange={(e) => handleCheckboxChange(e, item.id)}
                                  onClick={(e) => e.stopPropagation()}
                                  type="checkbox"
                                  className="mr-3 h-4 w-4 my-auto"
                                  name="checkBox"
                                  id="checkBox"
                                />
                                <div className="max-w-[300px] mr-10 font-bold whitespace-nowrap">
                                  {item.sender.fullname}
                                </div>
                              </div>
                              <div className="text-[13px]">
                                {formatTimestamp(item.created_at)}
                              </div>
                            </div>
                            <div className="mt-2">{item.subject}</div>
                            <div className="mt-2 text-[#3B3B3B]">
                              {item.message_content.length > 50
                                ? item.message_content.substring(0, 50) + "..."
                                : item.message_content}
                            </div>
                          </div>
                        ))}

                        <div className="flex justify-evenly items-center px-4 py-2 mt-2">
                          <button
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                            className="text-gray-600 disabled:opacity-50"
                          >
                            <MdChevronLeft size={30} />
                          </button>
                          <span>{`Page ${currentPage} of ${totalPages}`}</span>
                          <button
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                            className="text-gray-600 disabled:opacity-50"
                          >
                            <MdChevronRight size={30} />
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )}

                {toggleTab === 2 && !isfetchingDevregMessages && (
                  <>
                    {devregMessages.data.length === 0 && (
                      <h1 className="text-center py-[100px]">No Messages</h1>
                    )}
                    {devregMessages.data.length > 0 && (
                      devregMessages.data.map((item, index) => (
                        <div
                          key={index}
                          className={`py-2 px-4 cursor-pointer 
                            ${selectedIds.includes(item.id) ? 'bg-blue-300 text-white' : 'hover:bg-gray-100'}
                            border-b border-gray-200`}
                          onClick={() => {
                            setShowMessage(true);
                            handleOpenMessage(item);
                          }}
                        >
                          <div className="flex justify-between items-center">
                            <div className="flex">
                              <input
                                onChange={(e) => handleCheckboxChange(e, item.id)}
                                onClick={(e) => e.stopPropagation()}
                                type="checkbox"
                                className="mr-3 h-4 w-4 my-auto"
                                name="checkBox"
                                id="checkBox"
                              />
                              <div className="max-w-[300px] mr-10 font-bold whitespace-nowrap">
                                {item.sender.fullname}
                              </div>
                            </div>
                            <div className="text-[13px]">
                              {" "}
                              {formatTimestamp(item.created_at)}
                            </div>
                          </div>
                          <div className="mt-2 ">{item.subject}</div>
                          <div className="mt-2 text-[#3B3B3B] ">
                            {item.message_content.length > 50
                              ? item.message_content.substring(0, 50) + "..."
                              : item.message_content}
                          </div>
                        </div>
                      )))}
                    <div className="flex justify-evenly items-center px-4 py-2 mt-2">
                      <button
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        className="text-gray-600 disabled:opacity-50"
                      >
                        <MdChevronLeft size={30} />
                      </button>
                      <span>{`Page ${currentPage} of ${totalPages}`}</span>
                      <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="text-gray-600 disabled:opacity-50"
                      >
                        <MdChevronRight size={30} />
                      </button>
                    </div>
                  </>
                )}
                {toggleTab === 3 && (
                  <>
                    {adminMessages.data.length === 0 && (
                      <h1 className="text-center py-[100px]">No Messages</h1>
                    )}
                    {adminMessages.data.length === 0 && (
                      adminMessages.data.map((item, index) => (
                        <div
                          key={index}
                          className={`py-2 px-4 cursor-pointer 
                            ${selectedIds.includes(item.id) ? 'bg-blue-300 text-white' : 'hover:bg-gray-100'}
                            border-b border-gray-200`}
                          onClick={() => {
                            setShowMessage(true);
                            handleOpenMessage(item);
                          }}
                        >
                          <div className="flex justify-between items-center">
                            <div className="flex">
                              <input
                                onChange={(e) => handleCheckboxChange(e, item.id)}
                                onClick={(e) => e.stopPropagation()}
                                type="checkbox"
                                className="mr-3 h-4 w-4 my-auto"
                                name="checkBox"
                                id="checkBox"
                              />
                              <div className="max-w-[300px] mr-10 font-bold whitespace-nowrap">
                                {item.sender.fullname}
                              </div>
                            </div>
                            <div className="text-[13px]">
                              {" "}
                              {formatTimestamp(item.created_at)}
                            </div>
                          </div>
                          <div className="mt-2 ">{item.subject}</div>
                          <div className="mt-2 text-[#3B3B3B] ">
                            {item.msg.length > 50
                              ? item.msg.substring(0, 50) + "..."
                              : item.msg}
                          </div>
                        </div>
                      )))}
                    <div className="flex justify-evenly items-center px-4 py-2 mt-2">
                      <button
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        className="text-gray-600 disabled:opacity-50"
                      >
                        <MdChevronLeft size={30} />
                      </button>
                      <span>{`Page ${currentPage} of ${totalPages}`}</span>
                      <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="text-gray-600 disabled:opacity-50"
                      >
                        <MdChevronRight size={30} />
                      </button>
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          {showMessage && (
            <>
              <OpenMessageMobile data={messageContent} emit={emit} />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default MessagesMain;